import { StyledButton, StyledRouter } from './Button.styled'

export const Button = ({
  actionType,
  children,
  href,
  isButton,
  inverted,
  isRouter,
  to,
  ...props
}) => {
  return isRouter ? (
    <StyledRouter to={to} actionType={actionType} {...props}>
      {children}
    </StyledRouter>
  ) : isButton ? (
    <StyledButton as="button" actionType={actionType} {...props}>
      {children}
    </StyledButton>
  ) : (
    <StyledButton
      as="a"
      href={href}
      actionType={actionType}
      inverted={inverted}
      {...props}>
      {children}
    </StyledButton>
  )
}
