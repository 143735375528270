import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'
import { Heading } from '../Typo/Heading/Heading'

export const Modal = ({ children, title, showDialog, open, close }) => {
  return (
    <div>
      <DialogOverlay
        style={{ zIndex: 10 }}
        isOpen={showDialog}
        onDismiss={close}>
        <DialogContent
          aria-label="Kontaktformular"
          style={{
            boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)',
            borderRadius: '10px',
            width: '90vw',
            maxWidth: '800px',
            backgroundColor: '#E6D2C6',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Heading tag="h2">{title}</Heading>
            <p
              onClick={close}
              aria-label="Dialog schliessen"
              style={{
                fontSize: '40px',
                cursor: 'pointer',
                lineHeight: 0.8,
              }}>
              &#10799;
            </p>
          </div>
          {children}
        </DialogContent>
      </DialogOverlay>
    </div>
  )
}
