import { useEffect, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Link, NavLink } from 'react-router-dom'
import {
  StyledNavigation,
  StyledList,
  StyledListItem,
  StyledLogo,
  StyledCircle,
  StyledSubNav,
} from './Navigation.styled'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

const navItems = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Shooting',
    url: '/preise-leistungen',
    // subNav: [
    //   { label: 'Preise & Leistungen', url:/preise-leistungen '' },
    //   { label: 'Gutscheine', url: '/gutscheine' },
    // ],
  },
  {
    label: 'About me',
    url: '/construction',
  },
  {
    label: 'Portfolio',
    url: '/pferde-fotografie',
    // subNav: [
    //   { label: 'Pferdefotografie', url: '/pferde-fotografie' },
    //   { label: 'Hundefotografie', url: '/hunde-fotografie' },
    // ],
  },

  {
    label: 'Kontakt',
    url: '/kontakt',
  },
]

export const Navigation = ({ hasLogo, children, inverted, hasMobileLogo }) => {
  const [offset, setOffset] = useState(0)
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset)
    }
  }, [])

  const openSubNav = (id) => {
    const navItem = document.getElementById(id)
    navItem.classList.toggle('active')
  }

  const isScrolled = useCallback(() => {
    return offset > 200 ? true : false
  }, [offset])

  const { width } = useWindowDimensions()

  const isInverted = isScrolled() ? true : inverted

  return (
    <header>
      {width <= 992 && hasMobileLogo && (
        <Link to="/">
          <StyledLogo
            hasMobileLogo
            inverted={isInverted}
            $isScrolled={isScrolled()}
          />
        </Link>
      )}
      <StyledNavigation $isScrolled={isScrolled()} inverted={isInverted}>
        <div id="menuToggle" role="button">
          <input type="checkbox" />
          <StyledCircle />
          <span></span>
          <span></span>
          <span></span>

          <StyledList>
            {hasLogo && (
              <StyledListItem>
                <Link to="/">
                  <StyledLogo
                    inverted={isInverted}
                    $isScrolled={isScrolled()}
                  />
                </Link>
              </StyledListItem>
            )}
            {navItems.map((item, index) => {
              const { subNav, url, label } = item
              const id = `nav-item-${index}`
              return item.subNav ? (
                <StyledListItem
                  key={label}
                  inverted={isInverted}
                  $isScrolled={isScrolled()}
                  onClick={() => openSubNav(id)}
                  aria-label="Untermenü öffnen"
                  withSubNav
                  className="nav-list-item"
                  id={id}>
                  <button aria-hidden>
                    {label}
                    <span aria-hidden>&#9660;</span>
                  </button>
                  <StyledSubNav $isScrolled={isScrolled()} inverted={inverted}>
                    {subNav.map((item, index) => {
                      const { url, label } = item
                      return (
                        <StyledListItem
                          key={index}
                          inverted={isInverted}
                          $isScrolled={isScrolled()}>
                          <NavLink to={url}> {label}</NavLink>
                        </StyledListItem>
                      )
                    })}
                  </StyledSubNav>
                </StyledListItem>
              ) : (
                <StyledListItem
                  key={label}
                  inverted={isInverted}
                  $isScrolled={isScrolled()}>
                  <NavLink to={url}>{label}</NavLink>
                </StyledListItem>
              )
            })}
          </StyledList>
        </div>
      </StyledNavigation>
    </header>
  )
}
