import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { Home } from '../../pages/Home/Home'
import { Contact } from '../../pages/Contact/Contact'
import { Portfolio } from '../../pages/Portfolio/Portfolio'
import { Shooting } from '../../pages/Shooting/Shooting'
import { Construction } from '../../pages/Construction/Construction'
import { Four0Four } from '../../pages/404/404'

export const RouterProvider = ({ children }) => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/pferde-fotografie" element={<Portfolio />} />
        <Route path="/hunde-fotografie" element={<Construction />} />
        <Route path="/preise-leistungen" element={<Shooting />} />
        <Route path="/gutscheine" element={<Construction />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/404" element={<Four0Four />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </Router>
  )
}
