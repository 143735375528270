import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledGallerNavItem = styled.article(
  ({ theme }) => css`
    ${theme.breakpoint.m} {
      width: calc(50% - ${theme.spacing.superxl});
    }

    ${theme.breakpoint.xl} {
      width: calc(33.33% - ${theme.spacing.superxl});
    }

    > a > span {
      width: 100%;
    }

    div {
      background: ${theme.color.tertiary};
      padding: ${theme.spacing.xl};
      text-align: center;
      margin-top: ${theme.spacing.m};
    }

    span {
      overflow: hidden;
    }

    img {
      transition: all 0.3s linear !important;
    }

    &:hover img {
      transform: scale(1.02);
    }
  `
)

export const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  color: inherit;
`
