import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Typo } from '../Typo/Typo/Typo'
import { ReactComponent as Logo } from '../../media/images/logo.svg'

export const StyledFooter = styled.footer(
  ({ hasSocials, theme }) => css`
    display: ${hasSocials ? 'flex' : 'block'};
    flex-wrap: wrap;
    background-color: ${theme.color.tertiary};
    padding-top: ${theme.spacing.xl};
    justify-content: space-between;

    article {
      min-height: 10px;
      width: 100%;
      margin-bottom: ${theme.spacing.xl};
      text-align: center;

      &:nth-of-type(2) {
        max-width: 350px;
        margin: ${theme.spacing.xl} auto;
      }

      ${theme.breakpoint.m} {
        width: 20%;
        text-align: left;

        &:nth-of-type(1) {
          padding-left: 4vw;
        }

        &:nth-of-type(2) {
          width: 40%;
        }
      }
    }
  `
)

export const StyledFooterLink = styled(Link)(
  ({ theme }) => css`
    width: max-content;
    display: none;
    border-bottom: 1px solid ${theme.color.primary};
    text-decoration: none;
    color: ${theme.color.black};
    margin: 0 auto ${theme.spacing.l};

    ${theme.breakpoint.m} {
      display: block;
      width: 100%;
      padding: 0;
    }

    &:hover {
      font-weight: bold;
    }
  `
)

export const StyledTypo = styled(Typo)(
  ({ theme }) => css`
    border-left: 2px solid ${theme.color.primary};
    padding: ${theme.spacing.s};
    width: max-content;
    margin: ${theme.spacing.s} auto;

    ${theme.breakpoint.m} {
      margin: ${theme.spacing.s} 0;
    }
  `
)

export const StyledLogo = styled(Logo)(
  ({ theme }) => css`
    > * {
      fill: ${theme.color.black}!important;
    }
  `
)

export const FooterBar = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.secondary};
    padding: ${theme.spacing.xl};
    text-align: center;
    width: 100%;

    p {
      margin-bottom: 0;
    }

    a {
      color: ${theme.color.black};

      &:hover {
        font-weight: bold;
      }
    }
  `
)
