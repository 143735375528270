import { Section } from '../Section/Section'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import jaci from '../../media/images/lep_rezenssion_jacqueline-k.png'
import poldi from '../../media/images/lep_rezenssion_jacqueline-poldi.png'
import janina from '../../media/images/lep_rezenssion_janina-colandino.png'
import manu from '../../media/images/lep_rezenssion_manu-caramia.png'
import marie from '../../media/images/lep_rezenssion_marie-flambeu.png'
import chemero from '../../media/images/lep_rezenssion_tasja-chemero.png'
import tasja from '../../media/images/lep_rezenssion_tasja-f.png'
import { ImageText } from '../ImageText/ImageText'
import { Heading } from '../Typo/Heading/Heading'
import { Typo } from '../Typo/Typo/Typo'
import { StyledSlider } from './QuoteSlider.styled'

const quoteContent = [
  {
    img: tasja,
    alt: '',
    headline: 'Tasja F.',
    text: 'Es ist immer wieder eine Freude, mit Lea zusammen zu shooten. Sie sprudelt vor Kreativität und Ideen und setzt diese auf besondere Art um. Mit viel Geduld und dem Blick für das Besondere entstehen so Bilder, die man sich immer wieder gerne ansieht und die so zu wundervollen Erinnerungen werden.',
  },
  {
    img: jaci,
    alt: '',
    headline: 'Jacqueline K.',
    text: 'Lea ist sehr einfühlsam und geht gerne sofort auf persönliche Wünsche ein, bietet aber auch eigene Ideen an. Sie gibt einem ein sicheres Gefühl vor der Kamera. Die Bilder sind toll geworden. ',
  },
  {
    img: janina,
    alt: '',
    headline: 'Janina & Colandino',
    text: 'Lea hat schon so viele wunderschöne Momente von mir und meinen Pferden festgehalten. Und auch in meinen dunkelsten Momente hat sie kostbare Erinnerungen für immer gezaubert. Ich bin ihr unendlich dankbar! Ihre Geduld und Verständnis machen die Shootings sehr angenehm, sowohl für den Mensch als auch für die Tiere.',
  },
  {
    img: poldi,
    alt: '',
    headline: 'Jacqueline & Poldi',
    text: 'Lea hat ein Händchen für Tierphotographie. Sie geht auf jedes einzelne Tier individuell ein ganz nach deren Launen und macht tolle Bilder. Man merkt das sie das mit Leidenschaft macht und sich für jeden Kunden viel Zeit nimmt und viel Arbeit und Mühe in dir bearbeiteten Bildern steckt. Ich bin hoch zufrieden',
  },

  {
    img: chemero,
    alt: '',
    headline: 'Tasja & Chemero',
    text: 'Beim Shooting herrscht stets eine entspannte und vertraute Atmosphäre, sodass man sich immer vor der Kamera wohl fühlt. Neben der Tierfotografie ist man auch alleine bestens vor ihrer Kamera aufgehoben,  sodass besondere Aufnahmen entstehen. Danke für deine unendliche Geduld mit Chemero und mir, für deine grandiose Arbeit und um mich von meiner besten Seite darzustellen!',
  },
  {
    img: manu,
    alt: '',
    headline: 'Manu & Cara Mia',
    text: 'Ich habe bei Lea schon zweimal Bilder machen lassen. Jedes Mal bin ich so begeistert mit dem schönen Ergebnis. Sie nimmt sich wirklich viel Zeit. Durch ihre ruhige und gelassene Art ist das für Mensch und Tier wirklich ein angenehmes Shooting. Ich werde auf jeden Fall weitere Bilder machen lassen.',
  },
  {
    img: marie,
    alt: '',
    headline: 'Marie & Flambeau',
    text: 'Seit 2015 hat Lea immer wieder wunderschöne Erinnerungen von mir und meinem Flambeau gezaubert. Ihre Bilder sind unglaublich schön und strahlen so viel Wärme und Innigkeit aus. Ich würde immer wieder Bilder von Ihr machen lassen.',
  },
]

export const QuoteSlider = (quotes = quoteContent) => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    prevArrow: undefined,
    nextArrow: undefined,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  }
  return (
    <Section backgroundColor="#E6D2C6" fullwidth={false}>
      <StyledSlider {...settings}>
        {quoteContent.map((quote, index) => (
          <ImageText src={quote.img} alt={quote.alt} reverse key={index}>
            <Heading typo="h3">{quote.headline}</Heading>
            <Typo>{quote.text}</Typo>
          </ImageText>
        ))}
      </StyledSlider>
    </Section>
  )
}
