import styled, { css } from 'styled-components'

export const StyledImageText = styled.div(({ imageFirst, theme, reverse }) => {
  const { breakpoint } = theme
  return css`
    display: flex;
    flex-direction: ${reverse ? 'column-reverse' : 'column'};

    > * {
      width: 100%;
    }

    img {
      max-height: 600px;
      width: auto;
      max-width: 100%;
      margin: ${theme.spacing.superxl} auto;
      display: block;
    }

    ${breakpoint.m} {
      justify-content: flex-start;
      align-items: center;
      flex-direction: ${reverse ? 'row-reverse' : 'row'};

      > * {
        width: 50%;
      }
    }
  `
})

export const StyledContent = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.superxl};

    * {
      max-width: 490px;
      margin-left: 0;
      margin-right: 0;
    }
  `
)
