import { Navigation } from '../../components/Navigation/Navigation'
import { Typo } from '../../components/Typo/Typo/Typo'
import { Heading } from '../../components/Typo/Heading/Heading'
import { Section } from '../../components/Section/Section'
import { Footer } from '../../components/Footer/Footer'
import { Helmet } from 'react-helmet'
import errorImage from '../../media/images/404_page.png'
import { Image } from '../../components/Image/Image'
import { TextWrapper } from '../../components/Typo/TextWrapper'

const Head = () => (
  <Helmet>
    <title>Lea Ernst Photography</title>
    <meta name="description" content="" />
    <meta name="keywords" content="" />
  </Helmet>
)

export const Construction = () => {
  return (
    <>
      <Head />
      <Navigation hasLogo />
      {/* <Header src={headerImage} isCentered>
        <Logo style={{ width: '45vw', minWidth: '350px' }} />
      </Header> */}

      <main>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Section textAlign="center">
          <TextWrapper>
            <Image src={errorImage} />
            <Heading tag="h3">Under construction</Heading>
            <Typo>Diese Seite befindet sich aktuell im Aufbau.</Typo>
          </TextWrapper>
        </Section>
      </main>
      <Footer hasSocials />
    </>
  )
}
