import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

const getThemeColor = (theme, actionType) => {
  switch (actionType) {
    case 'primary':
      return theme.color.primary
    case 'secondary':
      return theme.color.secondary
    case 'black':
      return theme.color.black
    default:
      return theme.color.white
  }
}

const getThemeInvertedColor = (theme, actionType) => {
  switch (actionType) {
    case 'primary':
      return theme.color.white
    case 'secondary':
      return theme.color.white
    case 'black':
      return theme.color.white
    default:
      return theme.color.black
  }
}

const getButtonStyles = (
  theme,
  actionType,
  getThemeColor,
  getThemeInvertedColor,
  inverted
) => `
display: inline-block;
border: 2px solid ${getThemeColor(theme, actionType)};
font-size: ${theme.fontSize.s};
padding: ${theme.spacing.s} ${theme.spacing.l};
color: ${
  inverted
    ? getThemeInvertedColor(theme, actionType)
    : getThemeColor(theme, actionType)
};
margin: ${theme.spacing.l} 0;
text-transform: uppercase;
cursor: pointer;
background: ${inverted ? getThemeColor(theme, actionType) : 'transparent'};
transition: 0.3s;
width: 100%;
text-align: center;
text-decoration: none;

${theme.breakpoint.m} {
  width: auto;
}
&:hover {
  background: ${
    inverted
      ? getThemeInvertedColor(theme, actionType)
      : getThemeColor(theme, actionType)
  };
  color: ${
    inverted
      ? getThemeColor(theme, actionType)
      : getThemeInvertedColor(theme, actionType)
  };
}
`

export const StyledButton = styled.a(({ actionType, theme, inverted }) => {
  return css`
    ${getButtonStyles(
      theme,
      actionType,
      getThemeColor,
      getThemeInvertedColor,
      inverted
    )}
  `
})

export const StyledRouter = styled(Link)(({ actionType, theme, inverted }) => {
  return css`
    ${getButtonStyles(
      theme,
      actionType,
      getThemeColor,
      getThemeInvertedColor,
      inverted
    )}
  `
})
