import styled, { css } from 'styled-components'
import HorizontalScroll from 'react-scroll-horizontal'

export const StyledHorizontalScroll = styled(HorizontalScroll)(
  ({ offset, theme }) => {
    const offsetDesktop = offset ? offset : '278px'
    return css`
      height: calc(100vh - ${offsetDesktop}) !important;
      max-height: 800px;
      position: relative;

      &::before {
        overflow: hidden;
        content: '';
        position: fixed;
        right: -20px;
        width: 20px;
        z-index: 2;
        height: calc(100vh - ${offsetDesktop}) !important;
        box-shadow: -5px 0px 20px;
      }
    `
  }
)

export const StyledMobileScroll = styled.div(({ theme }) => {
  return css`
    display: flex;
    overflow: scroll;
    justify-content: flex-start;
    align-items: unset;
    height: 59vh;
    position: relative;

    &::before {
      overflow: hidden;
      content: '';
      position: fixed;
      right: -20px;
      width: 20px;
      z-index: 2;
      height: 58vh;
      box-shadow: -5px 0px 20px;
    }
  `
})

export const SrcollIndicator = styled.div(
  ({ theme }) => css`
    width: 100%;
    text-align: center;
    padding: ${theme.spacing.m};
  `
)
