import styled, { css } from 'styled-components'

export const StyledHeader = styled.header(({ theme, src, isCentered }) => {
  return css`
    width: 100vw;
    height: 350px;
    display: flex;
    justify-content: ${isCentered ? 'center' : 'flex-start'};
    align-items: center;
    padding: ${theme.spacing.superxl};
    background-image: url(${src});
    background-size: cover;
    background-position: center;

    ${theme.breakpoint.m} {
      height: 100vh;
    }
  `
})

export const StyledHeaderContent = styled.div(({ isCentered, theme }) => {
  return css`
    text-align: ${isCentered ? 'center' : 'left'};

    ${theme.breakpoint.m} {
      padding-top: 250px;
    }

    h1 {
      font-family: Sadio, Zapfino, decorative;
    }
  `
})
