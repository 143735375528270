import gallery01 from '../../media/images/gallery_horse/lep_galerie_horse_01.jpg'
import gallery02 from '../../media/images/gallery_horse/lep_galerie_horse_02.jpg'
import gallery03 from '../../media/images/gallery_horse/lep_galerie_horse_03.jpg'
import gallery04 from '../../media/images/gallery_horse/lep_galerie_horse_04.jpg'
import gallery06 from '../../media/images/gallery_horse/lep_galerie_horse_06.jpg'
import gallery08 from '../../media/images/gallery_horse/lep_galerie_horse_08.jpg'
import gallery09 from '../../media/images/gallery_horse/lep_galerie_horse_09.jpg'
import gallery10 from '../../media/images/gallery_horse/lep_galerie_horse_10.jpg'
import gallery11 from '../../media/images/gallery_horse/lep_galerie_horse_11.jpg'
import gallery12 from '../../media/images/gallery_horse/lep_galerie_horse_12.jpg'
import gallery13 from '../../media/images/gallery_horse/lep_galerie_horse_13.jpg'
import gallery14 from '../../media/images/gallery_horse/lep_galerie_horse_14.jpg'
import gallery15 from '../../media/images/gallery_horse/lep_galerie_horse_15.jpg'
import gallery16 from '../../media/images/gallery_horse/lep_galerie_horse_16.jpg'
import gallery17 from '../../media/images/gallery_horse/lep_galerie_horse_17.jpg'
import gallery19 from '../../media/images/gallery_horse/lep_galerie_horse_19.jpg'
import gallery20 from '../../media/images/gallery_horse/lep_galerie_horse_20.jpg'
import gallery21 from '../../media/images/gallery_horse/lep_galerie_horse_21.jpg'
import gallery24 from '../../media/images/gallery_horse/lep_galerie_horse_24.jpg'
import gallery26 from '../../media/images/gallery_horse/lep_galerie_horse_26.jpg'
import gallery27 from '../../media/images/gallery_horse/lep_galerie_horse_27.jpg'
import gallery28 from '../../media/images/gallery_horse/lep_galerie_horse_28.jpg'
import gallery29 from '../../media/images/gallery_horse/lep_galerie_horse_29.jpg'
import gallery30 from '../../media/images/gallery_horse/lep_galerie_horse_30.jpg'
import gallery31 from '../../media/images/gallery_horse/lep_galerie_horse_31.jpg'
import gallery32 from '../../media/images/gallery_horse/lep_galerie_horse_32.jpg'
import gallery33 from '../../media/images/gallery_horse/lep_galerie_horse_33.jpg'
import gallery34 from '../../media/images/gallery_horse/lep_galerie_horse_34.jpg'
import gallery101 from '../../media/images/gallery_horse/lep_galerie_horse_101.jpg'
import gallery102 from '../../media/images/gallery_horse/lep_galerie_horse_102.jpg'
import gallery103 from '../../media/images/gallery_horse/lep_galerie_horse_103.jpg'
import gallery104 from '../../media/images/gallery_horse/lep_galerie_horse_104.jpg'
import gallery105 from '../../media/images/gallery_horse/lep_galerie_horse_105.jpg'
import gallery106 from '../../media/images/gallery_horse/lep_galerie_horse_106.jpg'
import gallery107 from '../../media/images/gallery_horse/lep_galerie_horse_107.jpg'
import gallery108 from '../../media/images/gallery_horse/lep_galerie_horse_108.jpg'
import gallery109 from '../../media/images/gallery_horse/lep_galerie_horse_109.jpg'
import gallery110 from '../../media/images/gallery_horse/lep_galerie_horse_110.jpg'

export const horseImages = [
  gallery104,
  gallery08,
  gallery06,
  gallery03,
  gallery110,
  gallery04,
  gallery01,
  gallery02,
  gallery105,
  gallery09,
  gallery10,
  gallery108,
  gallery11,
  gallery12,
  gallery109,
  gallery13,
  gallery14,
  gallery107,
  gallery15,
  gallery16,
  gallery17,
  gallery103,
  gallery19,
  gallery20,
  gallery21,
  gallery106,
  gallery24,
  gallery26,
  gallery27,
  gallery102,
  gallery28,
  gallery29,
  gallery30,
  gallery31,
  gallery32,
  gallery101,
  gallery33,
  gallery34,
]
