import { Navigation } from '../../components/Navigation/Navigation'
import { StyledNavBlocker } from '../../components/Navigation/Navigation.styled'
import { Carousel } from '../../components/Carousel/Carousel'

import { Footer } from '../../components/Footer/Footer'
import { Helmet } from 'react-helmet'
import { horseImages } from '../../components/Carousel/horses.images'

const Head = () => (
  <Helmet>
    <title>Lea Ernst Photography</title>
    <meta name="description" content="" />
    <meta name="keywords" content="" />
  </Helmet>
)

export const Portfolio = () => {
  return (
    <>
      <Head />
      <Navigation hasLogo hasMobileLogo />
      <main>
        <StyledNavBlocker />
        <Carousel images={horseImages} />
      </main>
      <Footer />
    </>
  )
}
