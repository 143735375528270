import React from 'react'
import ReactDOM from 'react-dom'
import { RouterProvider } from './components/RouterProvider/RouterProvider'
import { Normalize } from 'styled-normalize'
import { Theme, GlobalStyle } from './styles'

ReactDOM.render(
  <Theme>
    <GlobalStyle />
    <Normalize />
    <RouterProvider />
  </Theme>,
  document.getElementById('root')
)
