import styled, { css } from 'styled-components'

export const StyledIcon = styled.a(
  ({ theme, color }) => css`
    margin-right: ${theme.spacing.l};
    svg {
      width: ${theme.spacing.superxl};
      height: ${theme.spacing.superxl};
      border-radius: ${theme.spacing.xl};
      box-shadow: 4px 6px 12px -3px #000000aa;
    }
    path {
      fill: ${color ? color : theme.color.primary};
    }
  `
)
