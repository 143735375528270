import { Parallax as ReactParallax } from 'react-parallax'
import { theme } from '../../styles/theme.js'
import { Section } from '../Section/Section.js'
import { StyledContentWrapper } from './Parallax.styled'

export const Parallax = ({
  children,
  src,
  alt,
  title,
  textAlign,
  inverted,
}) => {
  return (
    <ReactParallax
      bgImage={src}
      bgImageAlt={alt}
      strength={-90}
      bgImageStyle={{ minHeight: '110%' }}>
      <StyledContentWrapper>
        <Section
          textAlign={textAlign}
          textColor={inverted ? theme.color.black : theme.color.white}>
          {children}
        </Section>
      </StyledContentWrapper>
    </ReactParallax>
  )
}
