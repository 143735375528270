import styled, { css } from 'styled-components'

export const StyledSection = styled.section(
  ({ backgroundColor, textColor, theme, textAlign = 'left', fullwidth }) => {
    return css`
      background-color: ${backgroundColor ? backgroundColor : 'transparent'};
      margin: ${theme.spacing.superxl} auto;
      max-width: ${fullwidth ? 'none' : '1200px'};

      ${theme.breakpoint.m} {
        margin: 100px auto;
      }

      > div {
        max-width: 1400px;
        margin: 0 auto;
        color: ${textColor ? textColor : 'black'};
        text-align: ${textAlign};

        ${backgroundColor
          ? css`
              padding: ${theme.spacing.xxl} 0;
            `
          : ''}
      }
    `
  }
)
