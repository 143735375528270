import styled, { css } from 'styled-components'

export const StyledTypo = styled.div(
  ({
    color,
    fontSize = 's',
    fontFamily = 'text',
    fontWeight = 'inherit',
    isUppercase = false,
    inverted,
    hasUnderline = false,
    primary,
    spacing = 'm',
    textAlign = 'inherit',
    theme,
  }) => {
    return css`
      font-size: ${fontSize === 'superxl'
        ? theme.fontSize.xl
        : theme.fontSize[fontSize]};
      color: ${color ? theme.color[color] : 'inherit'};
      font-family: ${theme.font[fontFamily]};
      font-weight: ${fontWeight};
      text-transform: ${isUppercase ? 'uppercase' : 'inherit'};
      text-decoration: ${hasUnderline ? 'underline' : 'none'};
      text-align: ${textAlign};
      margin: 0 0 ${theme.spacing[spacing]};
      line-height: 1.5;
      white-space: pre-line;

      ${primary &&
      css`
        line-height: 1.7;
        color: ${inverted ? theme.color.white : theme.color.primary};
        font-family: ${theme.font.headline};
        padding-top: ${theme.spacing.l};
        font-size: ${theme.fontSize.l};
        margin-bottom: 0;
      `}

      ${theme.breakpoint.m} {
        font-size: ${theme.fontSize[fontSize]};
      }
    `
  }
)
