import styled, { css } from 'styled-components'

export const StyledFieldSet = styled.fieldset(
  ({ theme }) => css`
    border: 0;
    padding: 0;
    margin: 15px 0;
    position: relative;

    label {
      font-size: ${theme.fontSize.m};
      display: block;
    }

    input,
    select,
    textarea {
      padding: 15px 34px;
      font-size: ${theme.fontSize.m};
      width: 100%;
      margin: 10px 0;
      border: none;
      resize: vertical;

      &::placeholder {
        opacity: 0.4;
      }

      &:focus {
        outline: 2px solid ${theme.color.primary};
      }
    }

    select {
      appearance: none;
    }

    textarea {
      min-height: 200px;
      max-height: 300px;
    }

    .help {
      color: ${theme.color.primary};
    }
  `
)

export const StyledArrow = styled.div`
  &::after, &::before {
        border-right: 2px solid;
        content: '';
        display: block;
        height: 8px;
        position: absolute;
        transform: rotate(135deg);
        right: 25px;
        top: 70px;
        width: 0;
        pointer-events: none; 
      }
  
      &::after {
        right: 20px;
        transform: rotate(45deg);
      }
    }
  `
