import { StyledImage } from './Image.styled'
import 'react-lazy-load-image-component/src/effects/blur.css'
export const Image = ({ src, alt, backgroundImage, isSliderImage }) => {
  return (
    <StyledImage
      src={src}
      alt={alt}
      $backgroundImage={backgroundImage}
      $isSliderImage={isSliderImage}
    />
  )
}
