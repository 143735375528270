import { Navigation } from '../../components/Navigation/Navigation'
import { Typo } from '../../components/Typo/Typo/Typo'
import { Heading } from '../../components/Typo/Heading/Heading'
import { Section } from '../../components/Section/Section'
import { Header } from '../../components/Header/Header'
import headerImage from '../../media/images/header_shooting.jpg'
import { Button } from '../../components/Button/Button'
import { Parallax } from '../../components/Parallax/Parallax'
import parallax from '../../media/images/feature_shooting_parallax.jpg'
import { Footer } from '../../components/Footer/Footer'
import { ReactComponent as Logo } from '../../media/images/logo.svg'
import { Helmet } from 'react-helmet'
import { CardSection } from '../../components/CardSection/CardSection'
import pdf from '../../media/pdf/LeaErnstPhotography_Preise_Leistungen.pdf'
import { Modal } from '../../components/Modal/Modal'
import { useState } from 'react'
import { Formular } from '../../components/Form/Form'
import { TextWrapper } from '../../components/Typo/TextWrapper'

const Head = () => (
  <Helmet>
    <title>Lea Ernst Photography</title>
    <meta name="description" content="" />
    <meta name="keywords" content="" />
  </Helmet>
)

export const Shooting = () => {
  const [formData, setFormData] = useState({
    type: 'Shooting',
    packet: 'Small',
  })
  const [showDialog, setShowDialog] = useState(false)
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)
  const setData = (packet, type) => {
    open()
    setFormData({
      ...formData,
      packet,
      type,
    })
  }

  return (
    <>
      <Head />
      <Navigation hasLogo inverted />
      <Header src={headerImage} isCentered>
        <Logo style={{ width: '45vw', minWidth: '350px' }} />
      </Header>

      <main>
        <Modal
          open={open}
          close={close}
          showDialog={showDialog}
          title="Kontaktformular">
          <Formular data={formData} />
        </Modal>
        <Section textAlign="center">
          <TextWrapper>
            <Heading primary tag="h1" fontSize="xl">
              Preise und Leistungen
            </Heading>
            <Heading tag="h3">Meine Preise & Leistungen</Heading>
            <Typo>
              Im folgenden PDF findest du alle relevanten Informationen über
              meine Arbeit, die Preise sowie wichtiges über den Ablauf. Gerne
              stehe ich Dir auch beratend zur Seite, schreibe mir einfach eine
              Nachricht und ich helfe Dir gerne bei der Wahl für das richtige
              Paket. Sondervereinbarungen sind ebenfalls möglich und können über
              das Kontaktformular angefragt werden.
            </Typo>
          </TextWrapper>
        </Section>

        <Parallax src={parallax} alt="" textAlign="center">
          <Heading primary typo="h2" inverted fontSize="xl">
            Informationen
          </Heading>
          <Typo>
            Hier findest du meine Preise und Leistungen als PDF mit allen
            wichtigen Informationen rund um die Shootings.
          </Typo>
          <Button href={pdf} target="_blank">
            Zur Preisliste
          </Button>
        </Parallax>

        <Section textAlign="center">
          <TextWrapper>
            <Heading primary tag="h2" fontSize="xl">
              Shootingpakete
            </Heading>
            <Heading tag="h3">Shooting-Pakete im Vergleich</Heading>
            <Typo>
              Du kannst jeweils aus drei Shooting-Paketen mit unterschiedlichem
              Umfang wählen. Weitere Informationen über die einzelnen Pakete und
              Inhalte findest du in meiner Preise und Leistungen Übersicht als
              PDF. Solltest Du Fragen haben stehe ich Dir jederzeit gerne zur
              Verfügung, dafür nutze gerne das Kontaktformular oder kontaktiere
              mich persönlich per Telefon.
            </Typo>
          </TextWrapper>
        </Section>
        <CardSection setData={setData} />
      </main>
      <Footer hasSocials />
    </>
  )
}
