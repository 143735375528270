import { Typo } from '../Typo/Typo'

export const Heading = ({
  children,
  tag = 'h3',
  fontSize = 'm',
  spacing = 'm',
  ...props
}) => {
  return (
    <Typo tag={tag} fontSize={fontSize} spacing={spacing} {...props}>
      {children}
    </Typo>
  )
}
