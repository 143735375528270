import { CardWrapper } from '../CardSection/CardSection.styled'
import { Section } from '../Section/Section'
import gallery01 from '../../media/images/feature_home_galerie_01.jpg'
import gallery02 from '../../media/images/feature_home_galerie_02.jpg'
import gallery03 from '../../media/images/feature_home_galerie_03.jpg'
import { Image } from '../Image/Image'
import { Heading } from '../Typo/Heading/Heading'
import { Typo } from '../Typo/Typo/Typo'
import { StyledGallerNavItem, StyledLink } from './GalleryNavigation.styled'

const galleryItems = [
  {
    title: 'Erlebnisfotografie',
    text: 'Momente für die Ewigkeit.\nDeine persönliche Geschichte',
    img: gallery01,
    alt: 'Nahaufnahme eines Eheringes auf einer Hochzeit.',
    link: '/construction',
  },
  {
    title: 'Hundefotografie',
    text: 'Momente für die Ewigkeit\nmit deinem Hund',
    img: gallery02,
    alt: 'Hund im vollen Lauf.',
    link: '/construction',
  },
  {
    title: 'Pferdefotografie',
    text: 'Momente für die Ewigkeit\nmit deinem Pferd',
    img: gallery03,
    alt: 'Brauner Wallach im Sonnenschein mit glitzernder Mähne.',
    link: '/pferde-fotografie',
  },
]

export const GalleryNavigation = () => {
  return (
    <Section>
      <CardWrapper>
        {galleryItems.map((item, index) => {
          const { title, text, img, link } = item
          return (
            <StyledGallerNavItem key={index}>
              <StyledLink to={link}>
                <Image src={img} alt={title} />
                <div>
                  <Typo fontSize="s">{title}</Typo>
                  <Heading fontSize="m" tag="p">
                    {text}
                  </Heading>
                </div>
              </StyledLink>
            </StyledGallerNavItem>
          )
        })}
      </CardWrapper>
    </Section>
  )
}
