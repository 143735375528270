import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import { StyledSection } from '../Section/Section.styled'
import { StyledContent } from '../ImageText/ImageText.styled'

export const StyledSlider = styled(Slider)(
  ({ theme }) => css`
    ${theme.breakpoint.m} {
      padding: 0 100px;
    }

    .slick-dots {
      right: 0;
    }

    .slick-prev {
      left: 15px;
    }

    .slick-next {
      right: 15px;
    }

    .slick-arrow::before {
      opacity: 1;
    }

    ${StyledContent} {
      width: 100%;

      p {
        max-width: none;
      }
    }

    img {
      max-width: 150px;
      margin: 0 auto;
      max-height: 300px;

      ${theme.breakpoint.m} {
        max-width: 300px;
      }
    }

    ${StyledSection} {
      margin: 0;
    }
  `
)
