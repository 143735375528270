import styled, { css } from 'styled-components'
import formImage from '../../media/images/feature_kontakt_desktop.png'

export const StyledFormSection = styled.section(
  ({ theme }) => css`
    min-height: 100vh;
    position: relative;
    background-image: url(${formImage});
    background-size: 100vw;
    padding: 20px 0 200px;
    background-position: bottom;
    background-color: #eee;
    background-repeat: no-repeat;

    ${theme.breakpoint.l} {
      margin-top: 150px;
    }

    ${theme.breakpoint.xl} {
      background-position: 0 100px;
    }
  `
)

export const StyledContent = styled.div(
  ({ theme }) => css`
    max-width: 1200px;
    margin: 0 auto;
    /* padding: ${theme.spacing.superxl} 0; */

    ${theme.breakpoint.m} {
      display: flex;
      justify-content: space-between;
    }

    > div {
      position: relative;
      z-index: 1;
      padding: ${theme.spacing.xl};

      ${theme.breakpoint.m} {
        width: 50%;
      }

      &:last-of-type {
        ${theme.breakpoint.m} {
          margin-top: 20px;
        }
      }
    }
  `
)
