import { createGlobalStyle, css } from 'styled-components'
import quickpen from '../media/fonts/quickpen.otf'

export const GlobalStyle = createGlobalStyle(({ theme }) => {
  const { font } = theme
  return css`
    @font-face {
      font-family: 'nobel';
      src: url('https://use.typekit.net/af/8c68f7/00000000000000003b9ae84a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
          format('woff2'),
        url('https://use.typekit.net/af/8c68f7/00000000000000003b9ae84a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
          format('woff'),
        url('https://use.typekit.net/af/8c68f7/00000000000000003b9ae84a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
          format('opentype');
      font-display: auto;
      font-style: normal;
      font-weight: 300;
      font-stretch: normal;
    }

    @font-face {
      font-family: Quickpen;
      src: url(${quickpen}) format('opentype');
    }

    @font-face {
      font-family: Sadio;
      src: url(${quickpen}) format('opentype');
    }

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: black;
    }

    body {
      font-family: ${font.text};
      font-size: 16px;
    }

    .react-parallax-bgimage {
      filter: unset !important;
    }
  `
})
