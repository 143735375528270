import { Accordion as ReachAccordion, AccordionPanel } from '@reach/accordion'
import '@reach/accordion/styles.css'
import { Section } from '../Section/Section'
import { Heading } from '../Typo/Heading/Heading'
import { Typo } from '../Typo/Typo/Typo'

import { StyledAccordionButton, StyledAccordionItem } from './Accordion.styled'

export const Accordion = () => {
  return (
    <Section>
      <ReachAccordion collapsible multiple>
        <StyledAccordionItem>
          <StyledAccordionButton>
            <Heading fontSize="l" tag="h3" id="impressum">
              Impressum
            </Heading>
            <span aria-hidden className="open">
              &#9660;
            </span>
            <span aria-hidden className="close">
              &#9650;
            </span>
          </StyledAccordionButton>

          <AccordionPanel>
            <Typo fontSize="s" as="article">
              <h3>Diensteanbieter</h3>
              <p>Lea Ernst Photography, Lea Ernst</p>
              <p>Rolf-Pinegger-Straße 28</p>
              <p>80689 München</p>
              <p>Deutschland</p>
              <br />
              <h3>Kontaktmöglichkeiten</h3>
              <p>
                E-Mail-Adresse:&nbsp;
                <a href="mailto:leaernst.photography@gmail.com">
                  leaernst.photography@gmail.com
                </a>
              </p>
              <p>
                Telefon:&nbsp; <a href="tel:01749318082">01749318082</a>
              </p>
              <br />
              <h3>Social Media und andere Onlinepräsenzen</h3>
              <p>
                Dieses Impressum gilt auch für die folgenden
                Social-Media-Präsenzen und Onlineprofile:
              </p>
              <p>
                <a
                  href="https://www.instagram.com/lea.ernst.photography"
                  target="_blank"
                  rel="noreferrer">
                  https://www.instagram.com/lea.ernst.photography
                </a>
              </p>
              <p>
                <a
                  href="https://www.facebook.com/leaernstphotography"
                  target="_blank"
                  rel="noreferrer">
                  https://www.facebook.com/leaernstphotography
                </a>
              </p>
              <br />
              <h3>Haftungs- und Schutzrechtshinweise</h3>
              <p>
                <strong>Haftungsausschluss</strong>: Die Inhalte dieses
                Onlineangebotes wurden sorgfältig und nach unserem aktuellen
                Kenntnisstand erstellt, dienen jedoch nur der Information und
                entfalten keine rechtlich bindende Wirkung, sofern es sich nicht
                um gesetzlich verpflichtende Informationen (z.B. das Impressum,
                die Datenschutzerklärung, AGB oder verpflichtende Belehrungen
                von Verbrauchern) handelt. Wir behalten uns vor, die Inhalte
                vollständig oder teilweise zu ändern oder zu löschen, soweit
                vertragliche Verpflichtungen unberührt bleiben. Alle Angebote
                sind freibleibend und unverbindlich.{' '}
              </p>
              <p>
                <strong>Urheberrechte und Markenrechte</strong>: Alle auf dieser
                Website dargestellten Inhalte, wie Texte, Fotografien, Grafiken,
                Marken und Warenzeichen sind durch die jeweiligen Schutzrechte
                (Urheberrechte, Markenrechte) geschützt. Die Verwendung,
                Vervielfältigung usw. unterliegen unseren Rechten oder den
                Rechten der jeweiligen Urheber bzw. Rechteinhaber.
              </p>
              <p>
                <strong>Hinweise auf Rechtsverstöße</strong>: Sollten Sie
                innerhalb unseres Internetauftritts Rechtsverstöße bemerken,
                bitten wir Sie uns auf diese hinzuweisen. Wir werden
                rechtswidrige Inhalte und Links nach Kenntnisnahme unverzüglich
                entfernen.
              </p>
              <h3>Bildnachweise</h3>
              <p>
                Bildquellen und Urheberrechtshinweise: <br />
                Lea Ernst Photography
              </p>
            </Typo>
          </AccordionPanel>
        </StyledAccordionItem>
        <StyledAccordionItem>
          <StyledAccordionButton>
            <Heading fontSize="l" tag="h3" id="datenschutz">
              Datenschutz
            </Heading>
            <span aria-hidden className="open">
              &#9660;
            </span>
            <span aria-hidden className="close">
              &#9650;
            </span>
          </StyledAccordionButton>
          <AccordionPanel>
            <Typo fontSize="s" as="article">
              <h3>Einleitung</h3>
              <p>
                Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
                aufklären, welche Arten Ihrer personenbezogenen Daten
                (nachfolgend auch kurz als „Daten“ bezeichnet) wir zu welchen
                Zwecken und in welchem Umfang verarbeiten. Die
                Datenschutzerklärung gilt für alle von uns durchgeführten
                Verarbeitungen personenbezogener Daten, sowohl im Rahmen der
                Erbringung unserer Leistungen als auch insbesondere auf unseren
                Webseiten, in mobilen Applikationen sowie innerhalb externer
                Onlinepräsenzen, wie z.B. unserer Social-Media-Profile
                (nachfolgend zusammenfassend bezeichnet als „Onlineangebot“).
              </p>
              <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
              <p>Stand: 10. September 2021</p>
              <br />
              <h4>Inhaltsübersicht</h4>
              <ul>
                <li>Einleitung</li>
                <li>Verantwortlicher</li>
                <li>Übersicht der Verarbeitungen</li>
                <li>Maßgebliche Rechtsgrundlagen</li>
                <li>Sicherheitsmaßnahmen</li>
                <li>Übermittlung von personenbezogenen Daten</li>
                <li>Datenverarbeitung in Drittländern</li>
                <li>Löschung von Daten</li>
                <li>Einsatz von Cookies</li>
                <li>Bereitstellung des Onlineangebotes und Webhosting</li>
                <li>Kontakt- und Anfragenverwaltung</li>
                <li>
                  Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon
                </li>
                <li>Onlinemarketing</li>
                <li>Präsenzen in sozialen Netzwerken (Social Media)</li>
                <li>Plugins und eingebettete Funktionen sowie Inhalte</li>
                <li>Änderung und Aktualisierung der Datenschutzerklärung</li>
                <li>Rechte der betroffenen Personen</li>
                <li>Begriffsdefinitionen</li>
              </ul>
              <p>
                Verantwortlicher <br />
                Lea Ernst / Lea Ernst Photography <br />
                Rolf-Pinegger-Straße 28 <br />
                80689 München <br />
                Deutschland
              </p>
              <p>
                E-Mail-Adresse:{' '}
                <a href="mailto:eaernst.photography@gmail.com">
                  leaernst.photography@gmail.com
                </a>
              </p>
              <br />
              <p>
                Übersicht der Verarbeitungen <br />
                Die nachfolgende Übersicht fasst die Arten der verarbeiteten
                Daten und die Zwecke ihrer Verarbeitung zusammen und verweist
                auf die betroffenen Personen.
              </p>
              <br />
              <p>
                Arten der verarbeiteten Daten <br />
                Bestandsdaten (z.B. Namen, Adressen). <br />
                Inhaltsdaten (z.B. Eingaben in Onlineformularen). <br />
                Kontaktdaten (z.B. E-Mail, Telefonnummern). <br />
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen). <br />
                Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
                Zugriffszeiten). <br />
                Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
                Kundenkategorie). <br />
                Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
                Zahlungshistorie). <br />
                Kategorien betroffener Personen <br />
                Kommunikationspartner. <br />
                Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
              </p>{' '}
              <br />
              <p>
                Zwecke der Verarbeitung <br />
                Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.{' '}
                <br />
                Direktmarketing (z.B. per E-Mail oder postalisch). <br />
                Feedback (z.B. Sammeln von Feedback via Online-Formular). <br />
                Marketing. <br />
                Kontaktanfragen und Kommunikation. <br />
                Profile mit nutzerbezogenen Informationen (Erstellen von
                Nutzerprofilen). <br />
                Erbringung vertraglicher Leistungen und Kundenservice. <br />
                Maßgebliche Rechtsgrundlagen <br />
                Im Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen
                der DSGVO, auf deren Basis wir personenbezogene Daten
                verarbeiten. Bitte nehmen Sie zur Kenntnis, dass neben den
                Regelungen der DSGVO nationale Datenschutzvorgaben in Ihrem bzw.
                unserem Wohn- oder Sitzland gelten können. Sollten ferner im
                Einzelfall speziellere Rechtsgrundlagen maßgeblich sein, teilen
                wir Ihnen diese in der Datenschutzerklärung mit.
              </p>{' '}
              <br />
              <p>
                Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO) - Die betroffene
                Person hat ihre Einwilligung in die Verarbeitung der sie
                betreffenden personenbezogenen Daten für einen spezifischen
                Zweck oder mehrere bestimmte Zwecke gegeben. Vertragserfüllung
                und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO)
                - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
                Vertragspartei die betroffene Person ist, oder zur Durchführung
                vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
                betroffenen Person erfolgen. Berechtigte Interessen (Art. 6 Abs.
                1 S. 1 lit. f. DSGVO) - Die Verarbeitung ist zur Wahrung der
                berechtigten Interessen des Verantwortlichen oder eines Dritten
                erforderlich, sofern nicht die Interessen oder Grundrechte und
                Grundfreiheiten der betroffenen Person, die den Schutz
                personenbezogener Daten erfordern, überwiegen. Nationale
                Datenschutzregelungen in Deutschland: Zusätzlich zu den
                Datenschutzregelungen der Datenschutz-Grundverordnung gelten
                nationale Regelungen zum Datenschutz in Deutschland. Hierzu
                gehört insbesondere das Gesetz zum Schutz vor Missbrauch
                personenbezogener Daten bei der Datenverarbeitung
                (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere
                Spezialregelungen zum Recht auf Auskunft, zum Recht auf
                Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer
                Kategorien personenbezogener Daten, zur Verarbeitung für andere
                Zwecke und zur Übermittlung sowie automatisierten
                Entscheidungsfindung im Einzelfall einschließlich Profiling. Des
                Weiteren regelt es die Datenverarbeitung für Zwecke des
                Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im
                Hinblick auf die Begründung, Durchführung oder Beendigung von
                Beschäftigungsverhältnissen sowie die Einwilligung von
                Beschäftigten. Ferner können Landesdatenschutzgesetze der
                einzelnen Bundesländer zur Anwendung gelangen.
              </p>
              <br />
              <h4>Sicherheitsmaßnahmen</h4>
              <p>
                Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                Berücksichtigung des Stands der Technik, der
                Implementierungskosten und der Art, des Umfangs, der Umstände
                und der Zwecke der Verarbeitung sowie der unterschiedlichen
                Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
                Rechte und Freiheiten natürlicher Personen geeignete technische
                und organisatorische Maßnahmen, um ein dem Risiko angemessenes
                Schutzniveau zu gewährleisten.
              </p>
              <p>
                Zu den Maßnahmen gehören insbesondere die Sicherung der
                Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
                Kontrolle des physischen und elektronischen Zugangs zu den Daten
                als auch des sie betreffenden Zugriffs, der Eingabe, der
                Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung.
                Des Weiteren haben wir Verfahren eingerichtet, die eine
                Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
                Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
                berücksichtigen wir den Schutz personenbezogener Daten bereits
                bei der Entwicklung bzw. Auswahl von Hardware, Software sowie
                Verfahren entsprechend dem Prinzip des Datenschutzes, durch
                Technikgestaltung und durch datenschutzfreundliche
                Voreinstellungen.
              </p>
              <p>
                SSL-Verschlüsselung (https): Um Ihre via unserem Online-Angebot
                übermittelten Daten zu schützen, nutzen wir eine
                SSL-Verschlüsselung. Sie erkennen derart verschlüsselte
                Verbindungen an dem Präfix https:// in der Adresszeile Ihres
                Browsers.
              </p>
              <br />
              <h4>Übermittlung von personenbezogenen Daten</h4>
              <p>
                Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt
                es vor, dass die Daten an andere Stellen, Unternehmen, rechtlich
                selbstständige Organisationseinheiten oder Personen übermittelt
                oder sie ihnen gegenüber offengelegt werden. Zu den Empfängern
                dieser Daten können z.B. mit IT-Aufgaben beauftragte
                Dienstleister oder Anbieter von Diensten und Inhalten, die in
                eine Webseite eingebunden werden, gehören. In solchen Fall
                beachten wir die gesetzlichen Vorgaben und schließen
                insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem
                Schutz Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
              </p>
              <p>
                Datenübermittlung innerhalb der Organisation: Wir können
                personenbezogene Daten an andere Stellen innerhalb unserer
                Organisation übermitteln oder ihnen den Zugriff auf diese Daten
                gewähren. Sofern diese Weitergabe zu administrativen Zwecken
                erfolgt, beruht die Weitergabe der Daten auf unseren
                berechtigten unternehmerischen und betriebswirtschaftlichen
                Interessen oder erfolgt, sofern sie Erfüllung unserer
                vertragsbezogenen Verpflichtungen erforderlich ist oder wenn
                eine Einwilligung der Betroffenen oder eine gesetzliche
                Erlaubnis vorliegt.
              </p>
              <br />
              <h4>Datenverarbeitung in Drittländern</h4>
              <p>
                Sofern wir Daten in einem Drittland (d.h., außerhalb der
                Europäischen Union (EU), des Europäischen Wirtschaftsraums
                (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
                Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw.
                Übermittlung von Daten an andere Personen, Stellen oder
                Unternehmen stattfindet, erfolgt dies nur im Einklang mit den
                gesetzlichen Vorgaben.
              </p>
              <p>
                Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder
                gesetzlich erforderlicher Übermittlung verarbeiten oder lassen
                wir die Daten nur in Drittländern mit einem anerkannten
                Datenschutzniveau, vertraglichen Verpflichtung durch sogenannte
                Standardschutzklauseln der EU-Kommission, beim Vorliegen von
                Zertifizierungen oder verbindlicher internen
                Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,
                Informationsseite der EU-Kommission:
                https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de).
              </p>
              <br />
              <h4>Löschung von Daten</h4>
              <p>
                Die von uns verarbeiteten Daten werden nach Maßgabe der
                gesetzlichen Vorgaben gelöscht, sobald deren zur Verarbeitung
                erlaubten Einwilligungen widerrufen werden oder sonstige
                Erlaubnisse entfallen (z.B. wenn der Zweck der Verarbeitung
                dieser Daten entfallen ist oder sie für den Zweck nicht
                erforderlich sind).
              </p>
              <p>
                Sofern die Daten nicht gelöscht werden, weil sie für andere und
                gesetzlich zulässige Zwecke erforderlich sind, wird deren
                Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
                gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B.
                für Daten, die aus handels- oder steuerrechtlichen Gründen
                aufbewahrt werden müssen oder deren Speicherung zur
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                oder zum Schutz der Rechte einer anderen natürlichen oder
                juristischen Person erforderlich ist.
              </p>
              <p>
                Unsere Datenschutzhinweise können ferner weitere Angaben zu der
                Aufbewahrung und Löschung von Daten beinhalten, die für die
                jeweiligen Verarbeitungen vorrangig gelten.
              </p>
              <br />
              <h4>Einsatz von Cookies</h4>
              <p>
                Cookies sind Textdateien, die Daten von besuchten Websites oder
                Domains enthalten und von einem Browser auf dem Computer des
                Benutzers gespeichert werden. Ein Cookie dient in erster Linie
                dazu, die Informationen über einen Benutzer während oder nach
                seinem Besuch innerhalb eines Onlineangebotes zu speichern. Zu
                den gespeicherten Angaben können z.B. die Spracheinstellungen
                auf einer Webseite, der Loginstatus, ein Warenkorb oder die
                Stelle, an der ein Video geschaut wurde, gehören. Zu dem Begriff
                der Cookies zählen wir ferner andere Technologien, die die
                gleichen Funktionen wie Cookies erfüllen (z.B. wenn Angaben der
                Nutzer anhand pseudonymer Onlinekennzeichnungen gespeichert
                werden, auch als "Nutzer-IDs" bezeichnet)
              </p>
              <p>
                Die folgenden Cookie-Typen und Funktionen werden unterschieden:
              </p>
              <p>
                Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
                Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer
                ein Online-Angebot verlassen und seinen Browser geschlossen hat.
                Permanente Cookies: Permanente Cookies bleiben auch nach dem
                Schließen des Browsers gespeichert. So kann beispielsweise der
                Login-Status gespeichert oder bevorzugte Inhalte direkt
                angezeigt werden, wenn der Nutzer eine Website erneut besucht.
                Ebenso können die Interessen von Nutzern, die zur
                Reichweitenmessung oder zu Marketingzwecken verwendet werden, in
                einem solchen Cookie gespeichert werden. First-Party-Cookies:
                First-Party-Cookies werden von uns selbst gesetzt.
                Third-Party-Cookies (auch: Drittanbieter-Cookies):
                Drittanbieter-Cookies werden hauptsächlich von Werbetreibenden
                (sog. Dritten) verwendet, um Benutzerinformationen zu
                verarbeiten. Notwendige (auch: essentielle oder unbedingt
                erforderliche) Cookies: Cookies können zum einen für den Betrieb
                einer Webseite unbedingt erforderlich sein (z.B. um Logins oder
                andere Nutzereingaben zu speichern oder aus Gründen der
                Sicherheit). Statistik-, Marketing- und
                Personalisierungs-Cookies: Ferner werden Cookies im Regelfall
                auch im Rahmen der Reichweitenmessung eingesetzt sowie dann,
                wenn die Interessen eines Nutzers oder sein Verhalten (z.B.
                Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf
                einzelnen Webseiten in einem Nutzerprofil gespeichert werden.
                Solche Profile dienen dazu, den Nutzern z.B. Inhalte anzuzeigen,
                die ihren potentiellen Interessen entsprechen. Dieses Verfahren
                wird auch als "Tracking", d.h., Nachverfolgung der potentiellen
                Interessen der Nutzer bezeichnet. Soweit wir Cookies oder
                "Tracking"-Technologien einsetzen, informieren wir Sie gesondert
                in unserer Datenschutzerklärung oder im Rahmen der Einholung
                einer Einwilligung. Hinweise zu Rechtsgrundlagen: Auf welcher
                Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von
                Cookies verarbeiten, hängt davon ab, ob wir Sie um eine
                Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung
                von Cookies einwilligen, ist die Rechtsgrundlage der
                Verarbeitung Ihrer Daten die erklärte Einwilligung. Andernfalls
                werden die mithilfe von Cookies verarbeiteten Daten auf
                Grundlage unserer berechtigten Interessen (z.B. an einem
                betriebswirtschaftlichen Betrieb unseres Onlineangebotes und
                dessen Verbesserung) verarbeitet oder, wenn der Einsatz von
                Cookies erforderlich ist, um unsere vertraglichen
                Verpflichtungen zu erfüllen.
              </p>
              <p>
                Speicherdauer: Sofern wir Ihnen keine expliziten Angaben zur
                Speicherdauer von permanenten Cookies mitteilen (z. B. im Rahmen
                eines sog. Cookie-Opt-Ins), gehen Sie bitte davon aus, dass die
                Speicherdauer bis zu zwei Jahre betragen kann.
              </p>
              <p>
                Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out):
                Abhängig davon, ob die Verarbeitung auf Grundlage einer
                Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie
                jederzeit die Möglichkeit, eine erteilte Einwilligung zu
                widerrufen oder der Verarbeitung Ihrer Daten durch
                Cookie-Technologien zu widersprechen (zusammenfassend als
                "Opt-Out" bezeichnet). Sie können Ihren Widerspruch zunächst
                mittels der Einstellungen Ihres Browsers erklären, z.B., indem
                Sie die Nutzung von Cookies deaktivieren (wobei hierdurch auch
                die Funktionsfähigkeit unseres Onlineangebotes eingeschränkt
                werden kann). Ein Widerspruch gegen den Einsatz von Cookies zu
                Zwecken des Onlinemarketings kann auch mittels einer Vielzahl
                von Diensten, vor allem im Fall des Trackings, über die
                Webseiten https://optout.aboutads.info und
                https://www.youronlinechoices.com/ erklärt werden. Daneben
                können Sie weitere Widerspruchshinweise im Rahmen der Angaben zu
                den eingesetzten Dienstleistern und Cookies erhalten.
              </p>
              <p>
                Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:
                Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management
                ein, in dessen Rahmen die Einwilligungen der Nutzer in den
                Einsatz von Cookies, bzw. der im Rahmen des
                Cookie-Einwilligungs-Management-Verfahrens genannten
                Verarbeitungen und Anbieter eingeholt sowie von den Nutzern
                verwaltet und widerrufen werden können. Hierbei wird die
                Einwilligungserklärung gespeichert, um deren Abfrage nicht
                erneut wiederholen zu müssen und die Einwilligung entsprechend
                der gesetzlichen Verpflichtung nachweisen zu können. Die
                Speicherung kann serverseitig und/oder in einem Cookie
                (sogenanntes Opt-In-Cookie, bzw. mithilfe vergleichbarer
                Technologien) erfolgen, um die Einwilligung einem Nutzer, bzw.
                dessen Gerät zuordnen zu können. Vorbehaltlich individueller
                Angaben zu den Anbietern von Cookie-Management-Diensten, gelten
                die folgenden Hinweise: Die Dauer der Speicherung der
                Einwilligung kann bis zu zwei Jahren betragen. Hierbei wird ein
                pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt
                der Einwilligung, Angaben zur Reichweite der Einwilligung (z. B.
                welche Kategorien von Cookies und/oder Diensteanbieter) sowie
                dem Browser, System und verwendeten Endgerät gespeichert.
              </p>
              <p>
                Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen). Betroffene Personen: Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten). Rechtsgrundlagen:
                Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte
                Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO). Bereitstellung
                des Onlineangebotes und Webhosting Um unser Onlineangebot sicher
                und effizient bereitstellen zu können, nehmen wir die Leistungen
                von einem oder mehreren Webhosting-Anbietern in Anspruch, von
                deren Servern (bzw. von ihnen verwalteten Servern) das
                Onlineangebot abgerufen werden kann. Zu diesen Zwecken können
                wir Infrastruktur- und Plattformdienstleistungen,
                Rechenkapazität, Speicherplatz und Datenbankdienste sowie
                Sicherheitsleistungen und technische Wartungsleistungen in
                Anspruch nehmen.
              </p>
              <p>
                Zu den im Rahmen der Bereitstellung des Hostingangebotes
                verarbeiteten Daten können alle die Nutzer unseres
                Onlineangebotes betreffenden Angaben gehören, die im Rahmen der
                Nutzung und der Kommunikation anfallen. Hierzu gehören
                regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von
                Onlineangeboten an Browser ausliefern zu können, und alle
                innerhalb unseres Onlineangebotes oder von Webseiten getätigten
                Eingaben.
              </p>
              <p>
                E-Mail-Versand und -Hosting: Die von uns in Anspruch genommenen
                Webhosting-Leistungen umfassen ebenfalls den Versand, den
                Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken
                werden die Adressen der Empfänger sowie Absender als auch
                weitere Informationen betreffend den E-Mailversand (z.B. die
                beteiligten Provider) sowie die Inhalte der jeweiligen E-Mails
                verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der
                Erkennung von SPAM verarbeitet werden. Wir bitten darum, zu
                beachten, dass E-Mails im Internet grundsätzlich nicht
                verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar
                auf dem Transportweg verschlüsselt, aber (sofern kein
                sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt
                wird) nicht auf den Servern, von denen sie abgesendet und
                empfangen werden. Wir können daher für den Übertragungsweg der
                E-Mails zwischen dem Absender und dem Empfang auf unserem Server
                keine Verantwortung übernehmen.
              </p>
              <p>
                Erhebung von Zugriffsdaten und Logfiles: Wir selbst (bzw. unser
                Webhostinganbieter) erheben Daten zu jedem Zugriff auf den
                Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können
                die Adresse und Name der abgerufenen Webseiten und Dateien,
                Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung
                über erfolgreichen Abruf, Browsertyp nebst Version, das
                Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte
                Seite) und im Regelfall IP-Adressen und der anfragende Provider
                gehören.
              </p>
              <p>
                Die Serverlogfiles können zum einen zu Zwecken der Sicherheit
                eingesetzt werden, z.B., um eine Überlastung der Server zu
                vermeiden (insbesondere im Fall von missbräuchlichen Angriffen,
                sogenannten DDoS-Attacken) und zum anderen, um die Auslastung
                der Server und ihre Stabilität sicherzustellen.
              </p>
              <p>Lansol</p>
              <p>
                Verarbeitete Datenarten: Inhaltsdaten (z.B. Eingaben in
                Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen). Betroffene Personen: Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten). Zwecke der
                Verarbeitung: Bereitstellung unseres Onlineangebotes und
                Nutzerfreundlichkeit. Rechtsgrundlagen: Berechtigte Interessen
                (Art. 6 Abs. 1 S. 1 lit. f. DSGVO). Kontakt- und
                Anfragenverwaltung Bei der Kontaktaufnahme mit uns (z.B. per
                Kontaktformular, E-Mail, Telefon oder via soziale Medien) sowie
                im Rahmen bestehender Nutzer- und Geschäftsbeziehungen werden
                die Angaben der anfragenden Personen verarbeitet soweit dies zur
                Beantwortung der Kontaktanfragen und etwaiger angefragter
                Maßnahmen erforderlich ist.
              </p>
              <p>
                Die Beantwortung der Kontaktanfragen sowie die Verwaltung von
                Kontakt- und Anfragedaten im Rahmen von vertraglichen oder
                vorvertraglichen Beziehungen erfolgt zur Erfüllung unserer
                vertraglichen Pflichten oder zur Beantwortung von
                (vor)vertraglichen Anfragen und im Übrigen auf Grundlage der
                berechtigten Interessen an der Beantwortung der Anfragen und
                Pflege von Nutzer- bzw. Geschäftsbeziehungen.
              </p>
              <p>
                Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
                Kontaktdaten (z.B. E-Mail, Telefonnummern), Inhaltsdaten (z.B.
                Eingaben in Onlineformularen). Betroffene Personen:
                Kommunikationspartner. Zwecke der Verarbeitung: Kontaktanfragen
                und Kommunikation. Rechtsgrundlagen: Vertragserfüllung und
                vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO),
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                Werbliche Kommunikation via E-Mail, Post, Fax oder Telefon Wir
                verarbeiten personenbezogene Daten zu Zwecken der werblichen
                Kommunikation, die über diverse Kanäle, wie z.B. E-Mail,
                Telefon, Post oder Fax, entsprechend den gesetzlichen Vorgaben
                erfolgen kann.
              </p>
              <p>
                Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit
                zu widerrufen oder der werblichen Kommunikation jederzeit zu
                widersprechen.
              </p>
              <p>
                Nach Widerruf bzw. Widerspruch können wir die zum Nachweis der
                Einwilligung erforderlichen Daten bis zu drei Jahren auf
                Grundlage unserer berechtigten Interessen speichern, bevor wir
                sie löschen. Die Verarbeitung dieser Daten wird auf den Zweck
                einer möglichen Abwehr von Ansprüchen beschränkt. Ein
                individueller Löschungsantrag ist jederzeit möglich, sofern
                zugleich das ehemalige Bestehen einer Einwilligung bestätigt
                wird.
              </p>
              <p>
                Verarbeitete Datenarten: Bestandsdaten (z.B. Namen, Adressen),
                Kontaktdaten (z.B. E-Mail, Telefonnummern). Betroffene Personen:
                Kommunikationspartner. Zwecke der Verarbeitung: Direktmarketing
                (z.B. per E-Mail oder postalisch). Rechtsgrundlagen:
                Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO), Berechtigte
                Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO). Onlinemarketing
                Wir verarbeiten personenbezogene Daten zu Zwecken des
                Onlinemarketings, worunter insbesondere die Vermarktung von
                Werbeflächen oder Darstellung von werbenden und sonstigen
                Inhalten (zusammenfassend als "Inhalte" bezeichnet) anhand
                potentieller Interessen der Nutzer sowie die Messung ihrer
                Effektivität fallen kann.
              </p>
              <p>
                Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und
                in einer Datei (sogenannte "Cookie") gespeichert oder ähnliche
                Verfahren genutzt, mittels derer die für die Darstellung der
                vorgenannten Inhalte relevante Angaben zum Nutzer gespeichert
                werden. Zu diesen Angaben können z.B. betrachtete Inhalte,
                besuchte Webseiten, genutzte Onlinenetzwerke, aber auch
                Kommunikationspartner und technische Angaben, wie der verwendete
                Browser, das verwendete Computersystem sowie Angaben zu
                Nutzungszeiten gehören. Sofern Nutzer in die Erhebung ihrer
                Standortdaten eingewilligt haben, können auch diese verarbeitet
                werden.
              </p>
              <p>
                Es werden ebenfalls die IP-Adressen der Nutzer gespeichert.
                Jedoch nutzen wir zur Verfügung stehende IP-Masking-Verfahren
                (d.h., Pseudonymisierung durch Kürzung der IP-Adresse) zum
                Schutz der Nutzer. Generell werden im Rahmen des
                Onlinemarketingverfahren keine Klardaten der Nutzer (wie z.B.
                E-Mail-Adressen oder Namen) gespeichert, sondern Pseudonyme.
                D.h., wir als auch die Anbieter der Onlinemarketingverfahren
                kennen nicht die tatsächlich Identität der Nutzer, sondern nur
                die in deren Profilen gespeicherten Angaben.
              </p>
              <p>
                Die Angaben in den Profilen werden im Regelfall in den Cookies
                oder mittels ähnlicher Verfahren gespeichert. Diese Cookies
                können später generell auch auf anderen Webseiten die dasselbe
                Onlinemarketingverfahren einsetzen, ausgelesen und zu Zwecken
                der Darstellung von Inhalten analysiert als auch mit weiteren
                Daten ergänzt und auf dem Server des
                Onlinemarketingverfahrensanbieters gespeichert werden.
              </p>
              <p>
                Ausnahmsweise können Klardaten den Profilen zugeordnet werden.
                Das ist der Fall, wenn die Nutzer z.B. Mitglieder eines sozialen
                Netzwerks sind, dessen Onlinemarketingverfahren wir einsetzen
                und das Netzwerk die Profile der Nutzer mit den vorgenannten
                Angaben verbindet. Wir bitten darum, zu beachten, dass Nutzer
                mit den Anbietern zusätzliche Abreden, z.B. durch Einwilligung
                im Rahmen der Registrierung, treffen können.
              </p>
              <p>
                Wir erhalten grundsätzlich nur Zugang zu zusammengefassten
                Informationen über den Erfolg unserer Werbeanzeigen. Jedoch
                können wir im Rahmen sogenannter Konversionsmessungen prüfen,
                welche unserer Onlinemarketingverfahren zu einer sogenannten
                Konversion geführt haben, d.h. z.B., zu einem Vertragsschluss
                mit uns. Die Konversionsmessung wird alleine zur Analyse des
                Erfolgs unserer Marketingmaßnahmen verwendet.
              </p>
              <p>
                Solange nicht anders angegeben, bitten wir Sie davon auszugehen,
                dass verwendete Cookies für einen Zeitraum von zwei Jahren
                gespeichert werden.
              </p>
              <p>
                Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
                Einwilligung in den Einsatz der Drittanbieter bitten, ist die
                Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
                Ansonsten werden die Daten der Nutzer auf Grundlage unserer
                berechtigten Interessen (d.h. Interesse an effizienten,
                wirtschaftlichen und empfängerfreundlichen Leistungen)
                verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
                Informationen zur Verwendung von Cookies in dieser
                Datenschutzerklärung hinweisen.
              </p>
              <p>
                Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen). Betroffene Personen: Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten). Zwecke der
                Verarbeitung: Marketing, Profile mit nutzerbezogenen
                Informationen (Erstellen von Nutzerprofilen).
                Sicherheitsmaßnahmen: IP-Masking (Pseudonymisierung der
                IP-Adresse). Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 S. 1
                lit. a. DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
                f. DSGVO). Widerspruchsmöglichkeit (Opt-Out): Wir verweisen auf
                die Datenschutzhinweise der jeweiligen Anbieter und die zu den
                Anbietern angegebenen Widerspruchsmöglichkeiten (sog.
                "Opt-Out"). Sofern keine explizite Opt-Out-Möglichkeit angegeben
                wurde, besteht zum einen die Möglichkeit, dass Sie Cookies in
                den Einstellungen Ihres Browsers abschalten. Hierdurch können
                jedoch Funktionen unseres Onlineangebotes eingeschränkt werden.
                Wir empfehlen daher zusätzlich die folgenden
                Opt-Out-Möglichkeiten, die zusammenfassend auf jeweilige Gebiete
                gerichtet angeboten werden: a) Europa:
                https://www.youronlinechoices.eu. b) Kanada:
                https://www.youradchoices.ca/choices. c) USA:
                https://www.aboutads.info/choices. d) Gebietsübergreifend:
                https://optout.aboutads.info. Eingesetzte Dienste und
                Diensteanbieter:
              </p>
              <p>
                Google Analytics: Onlinemarketing und Webanalyse;
                Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
                Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
                https://marketingplatform.google.com/intl/de/about/analytics/;
                Datenschutzerklärung: https://policies.google.com/privacy;
                Widerspruchsmöglichkeit (Opt-Out): Opt-Out-Plugin:
                https://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen
                für die Darstellung von Werbeeinblendungen:
                https://adssettings.google.com/authenticated. Präsenzen in
                sozialen Netzwerken (Social Media) Wir unterhalten
                Onlinepräsenzen innerhalb sozialer Netzwerke und verarbeiten in
                diesem Rahmen Daten der Nutzer, um mit den dort aktiven Nutzern
                zu kommunizieren oder um Informationen über uns anzubieten.
              </p>
              <p>
                Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des
                Raumes der Europäischen Union verarbeitet werden können.
                Hierdurch können sich für die Nutzer Risiken ergeben, weil so
                z.B. die Durchsetzung der Rechte der Nutzer erschwert werden
                könnte.
              </p>
              <p>
                Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke
                im Regelfall für Marktforschungs- und Werbezwecke verarbeitet.
                So können z.B. anhand des Nutzungsverhaltens und sich daraus
                ergebender Interessen der Nutzer Nutzungsprofile erstellt
                werden. Die Nutzungsprofile können wiederum verwendet werden, um
                z.B. Werbeanzeigen innerhalb und außerhalb der Netzwerke zu
                schalten, die mutmaßlich den Interessen der Nutzer entsprechen.
                Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern
                der Nutzer gespeichert, in denen das Nutzungsverhalten und die
                Interessen der Nutzer gespeichert werden. Ferner können in den
                Nutzungsprofilen auch Daten unabhängig der von den Nutzern
                verwendeten Geräte gespeichert werden (insbesondere, wenn die
                Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen
                eingeloggt sind).
              </p>
              <p>
                Für eine detaillierte Darstellung der jeweiligen
                Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out)
                verweisen wir auf die Datenschutzerklärungen und Angaben der
                Betreiber der jeweiligen Netzwerke.
              </p>
              <p>
                Auch im Fall von Auskunftsanfragen und der Geltendmachung von
                Betroffenenrechten weisen wir darauf hin, dass diese am
                effektivsten bei den Anbietern geltend gemacht werden können.
                Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer
                und können direkt entsprechende Maßnahmen ergreifen und
                Auskünfte geben. Sollten Sie dennoch Hilfe benötigen, dann
                können Sie sich an uns wenden.
              </p>
              <p>
                Facebook: Wir sind gemeinsam mit Facebook Irland Ltd. für die
                Erhebung (jedoch nicht die weitere Verarbeitung) von Daten der
                Besucher unserer Facebook-Seite (sog. "Fanpage") verantwortlich.
                Zu diesen Daten gehören Informationen zu den Arten von Inhalten,
                die Nutzer sich ansehen oder mit denen sie interagieren, oder
                die von ihnen vorgenommenen Handlungen (siehe unter „Von dir und
                anderen getätigte und bereitgestellte Dinge“ in der
                Facebook-Datenrichtlinie: https://www.facebook.com/policy),
                sowie Informationen über die von den Nutzern genutzten Geräte
                (z. B. IP-Adressen, Betriebssystem, Browsertyp,
                Spracheinstellungen, Cookie-Daten; siehe unter
                „Geräteinformationen“ in der Facebook-Datenrichtlinie-erklärung:
                https://www.facebook.com/policy). Wie in der
                Facebook-Datenrichtlinie unter „Wie verwenden wir diese
                Informationen?“ erläutert, erhebt und verwendet Facebook
                Informationen auch, um Analysedienste, so genannte
                "Seiten-Insights", für Seitenbetreiber bereitzustellen, damit
                diese Erkenntnisse darüber erhalten, wie Personen mit ihren
                Seiten und mit den mit ihnen verbundenen Inhalten interagieren.
                Wir haben mit Facebook eine spezielle Vereinbarung abgeschlossen
                ("Informationen zu Seiten-Insights",
                https://www.facebook.com/legal/terms/page_controller_addendum),
                in der insbesondere geregelt wird, welche Sicherheitsmaßnahmen
                Facebook beachten muss und in der Facebook sich bereit erklärt
                hat die Betroffenenrechte zu erfüllen (d. h. Nutzer können z. B.
                Auskünfte oder Löschungsanfragen direkt an Facebook richten).
                Die Rechte der Nutzer (insbesondere auf Auskunft, Löschung,
                Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde),
                werden durch die Vereinbarungen mit Facebook nicht
                eingeschränkt. Weitere Hinweise finden sich in den
                "Informationen zu Seiten-Insights"
                (https://www.facebook.com/legal/terms/information_about_page_insights_data).
              </p>
              <p>
                Verarbeitete Datenarten: Kontaktdaten (z.B. E-Mail,
                Telefonnummern), Inhaltsdaten (z.B. Eingaben in
                Onlineformularen), Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen). Betroffene Personen: Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten). Zwecke der
                Verarbeitung: Kontaktanfragen und Kommunikation, Feedback (z.B.
                Sammeln von Feedback via Online-Formular), Marketing.
                Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f. DSGVO). Eingesetzte Dienste und Diensteanbieter:
              </p>
              <p>
                Instagram: Soziales Netzwerk; Dienstanbieter: Instagram Inc.,
                1601 Willow Road, Menlo Park, CA, 94025, USA, Mutterunternehmen:
                Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website:
                https://www.instagram.com; Datenschutzerklärung:
                https://instagram.com/about/legal/privacy. Facebook: Soziales
                Netzwerk; Dienstanbieter: Facebook Ireland Ltd., 4 Grand Canal
                Square, Grand Canal Harbour, Dublin 2, Irland,
                Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025,
                USA; Website: https://www.facebook.com; Datenschutzerklärung:
                https://www.facebook.com/about/privacy; Widerspruchsmöglichkeit
                (Opt-Out): Einstellungen für Werbeanzeigen:
                https://www.facebook.com/adpreferences/ad_settings (Login bei
                Facebook ist erforderlich). Plugins und eingebettete Funktionen
                sowie Inhalte Wir binden in unser Onlineangebot Funktions- und
                Inhaltselemente ein, die von den Servern ihrer jeweiligen
                Anbieter (nachfolgend bezeichnet als "Drittanbieter”) bezogen
                werden. Dabei kann es sich zum Beispiel um Grafiken, Videos oder
                Stadtpläne handeln (nachfolgend einheitlich bezeichnet als
                "Inhalte”).
              </p>
              <p>
                Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
                Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne die
                IP-Adresse die Inhalte nicht an deren Browser senden könnten.
                Die IP-Adresse ist damit für die Darstellung dieser Inhalte oder
                Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
                verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
                Auslieferung der Inhalte verwenden. Drittanbieter können ferner
                sogenannte Pixel-Tags (unsichtbare Grafiken, auch als "Web
                Beacons" bezeichnet) für statistische oder Marketingzwecke
                verwenden. Durch die "Pixel-Tags" können Informationen, wie der
                Besucherverkehr auf den Seiten dieser Webseite, ausgewertet
                werden. Die pseudonymen Informationen können ferner in Cookies
                auf dem Gerät der Nutzer gespeichert werden und unter anderem
                technische Informationen zum Browser und zum Betriebssystem, zu
                verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben
                zur Nutzung unseres Onlineangebotes enthalten als auch mit
                solchen Informationen aus anderen Quellen verbunden werden.
              </p>
              <p>
                Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
                Einwilligung in den Einsatz der Drittanbieter bitten, ist die
                Rechtsgrundlage der Verarbeitung von Daten die Einwilligung.
                Ansonsten werden die Daten der Nutzer auf Grundlage unserer
                berechtigten Interessen (d.h. Interesse an effizienten,
                wirtschaftlichen und empfängerfreundlichen Leistungen)
                verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die
                Informationen zur Verwendung von Cookies in dieser
                Datenschutzerklärung hinweisen.
              </p>
              <p>
                Instagram-Plugins und -Inhalte: Wir sind gemeinsam mit Facebook
                Irland Ltd. für die Erhebung oder den Erhalt im Rahmen einer
                Übermittlung (jedoch nicht die weitere Verarbeitung) von
                "Event-Daten", die Facebook mittels Funktionen von Instagram (z.
                B. Einbettungsfunktionen für Inhalte), die auf unserem
                Onlineangebot ausgeführt werden, erhebt oder im Rahmen einer
                Übermittlung zu folgenden Zwecken erhält, gemeinsam
                verantwortlich: a) Anzeige von Inhalten sowie
                Werbeinformationen, die den mutmaßlichen Interessen der Nutzer
                entsprechen; b) Zustellung kommerzieller und
                transaktionsbezogener Nachrichten (z. B. Ansprache von Nutzern
                via Facebook-Messenger); c) Verbesserung der
                Anzeigenauslieferung und Personalisierung von Funktionen und
                Inhalten (z. B. Verbesserung der Erkennung, welche Inhalte oder
                Werbeinformationen mutmaßlich den Interessen der Nutzer
                entsprechen). Wir haben mit Facebook eine spezielle Vereinbarung
                abgeschlossen ("Zusatz für Verantwortliche",
                https://www.facebook.com/legal/controller_addendum), in der
                insbesondere geregelt wird, welche Sicherheitsmaßnahmen Facebook
                beachten muss
                (https://www.facebook.com/legal/terms/data_security_terms) und
                in der Facebook sich bereit erklärt hat die Betroffenenrechte zu
                erfüllen (d. h. Nutzer können z. B. Auskünfte oder
                Löschungsanfragen direkt an Facebook richten). Hinweis: Wenn
                Facebook uns Messwerte, Analysen und Berichte bereitstellt (die
                aggregiert sind, d. h. keine Angaben zu einzelnen Nutzern
                erhalten und für uns anonym sind), dann erfolgt diese
                Verarbeitung nicht im Rahmen der gemeinsamen Verantwortlichkeit,
                sondern auf Grundlage eines Auftragsverarbeitungsvertrages
                ("Datenverarbeitungsbedingungen ",
                https://www.facebook.com/legal/terms/dataprocessing) , der
                "Datensicherheitsbedingungen"
                (https://www.facebook.com/legal/terms/data_security_terms) sowie
                im Hinblick auf die Verarbeitung in den USA auf Grundlage von
                Standardvertragsklauseln ("Facebook-EU-Datenübermittlungszusatz,
                https://www.facebook.com/legal/EU_data_transfer_addendum). Die
                Rechte der Nutzer (insbesondere auf Auskunft, Löschung,
                Widerspruch und Beschwerde bei zuständiger Aufsichtsbehörde),
                werden durch die Vereinbarungen mit Facebook nicht
                eingeschränkt.
              </p>
              <p>
                Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten,
                Interesse an Inhalten, Zugriffszeiten),
                Meta-/Kommunikationsdaten (z.B. Geräte-Informationen,
                IP-Adressen). Betroffene Personen: Nutzer (z.B.
                Webseitenbesucher, Nutzer von Onlinediensten). Zwecke der
                Verarbeitung: Bereitstellung unseres Onlineangebotes und
                Nutzerfreundlichkeit, Erbringung vertraglicher Leistungen und
                Kundenservice, Marketing, Profile mit nutzerbezogenen
                Informationen (Erstellen von Nutzerprofilen). Rechtsgrundlagen:
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO),
                Einwilligung (Art. 6 Abs. 1 S. 1 lit. a. DSGVO). Eingesetzte
                Dienste und Diensteanbieter:
              </p>
              <p>
                Google Fonts: Wir binden die Schriftarten ("Google Fonts") des
                Anbieters Google ein, wobei die Daten der Nutzer allein zu
                Zwecken der Darstellung der Schriftarten im Browser der Nutzer
                verwendet werden. Die Einbindung erfolgt auf Grundlage unserer
                berechtigten Interessen an einer technisch sicheren,
                wartungsfreien und effizienten Nutzung von Schriftarten, deren
                einheitlicher Darstellung sowie unter Berücksichtigung möglicher
                lizenzrechtlicher Restriktionen für deren Einbindung.
                Dienstanbieter: Google Ireland Limited, Gordon House, Barrow
                Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600
                Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
                https://fonts.google.com/; Datenschutzerklärung:
                https://policies.google.com/privacy. Google Maps: Wir binden die
                Landkarten des Dienstes “Google Maps” des Anbieters Google ein.
                Zu den verarbeiteten Daten können insbesondere IP-Adressen und
                Standortdaten der Nutzer gehören, die jedoch nicht ohne deren
                Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer
                Mobilgeräte vollzogen), erhoben werden; Dienstanbieter: Google
                Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
                Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway,
                Mountain View, CA 94043, USA; Website:
                https://cloud.google.com/maps-platform; Datenschutzerklärung:
                https://policies.google.com/privacy; Widerspruchsmöglichkeit
                (Opt-Out): Opt-Out-Plugin:
                https://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen
                für die Darstellung von Werbeeinblendungen:
                https://adssettings.google.com/authenticated. Instagram-Plugins
                und -Inhalte: Instagram Plugins und -Inhalte - Hierzu können
                z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen
                gehören, mit denen Nutzer Inhalte dieses Onlineangebotes
                innerhalb von Instagram teilen können. Dienstanbieter:
                https://www.instagram.com, Instagram Inc., 1601 Willow Road,
                Menlo Park, CA, 94025, USA; Website: https://www.instagram.com;
                Datenschutzerklärung: https://instagram.com/about/legal/privacy.
                Änderung und Aktualisierung der Datenschutzerklärung Wir bitten
                Sie, sich regelmäßig über den Inhalt unserer
                Datenschutzerklärung zu informieren. Wir passen die
                Datenschutzerklärung an, sobald die Änderungen der von uns
                durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
                informieren Sie, sobald durch die Änderungen eine
                Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine
                sonstige individuelle Benachrichtigung erforderlich wird.
              </p>
              <p>
                Sofern wir in dieser Datenschutzerklärung Adressen und
                Kontaktinformationen von Unternehmen und Organisationen angeben,
                bitten wir zu beachten, dass die Adressen sich über die Zeit
                ändern können und bitten die Angaben vor Kontaktaufnahme zu
                prüfen.
              </p>
              <p>
                Rechte der betroffenen Personen Ihnen stehen als Betroffene nach
                der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art.
                15 bis 21 DSGVO ergeben:
              </p>
              <p>
                Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich
                aus Ihrer besonderen Situation ergeben, jederzeit gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten, die
                aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                Widerspruch einzulegen; dies gilt auch für ein auf diese
                Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
                personenbezogenen Daten verarbeitet, um Direktwerbung zu
                betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten zum
                Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                Profiling, soweit es mit solcher Direktwerbung in Verbindung
                steht. Widerrufsrecht bei Einwilligungen: Sie haben das Recht,
                erteilte Einwilligungen jederzeit zu widerrufen. Auskunftsrecht:
                Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
                betreffende Daten verarbeitet werden und auf Auskunft über diese
                Daten sowie auf weitere Informationen und Kopie der Daten
                entsprechend den gesetzlichen Vorgaben. Recht auf Berichtigung:
                Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die
                Vervollständigung der Sie betreffenden Daten oder die
                Berichtigung der Sie betreffenden unrichtigen Daten zu
                verlangen. Recht auf Löschung und Einschränkung der
                Verarbeitung: Sie haben nach Maßgabe der gesetzlichen Vorgaben
                das Recht, zu verlangen, dass Sie betreffende Daten unverzüglich
                gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen
                Vorgaben eine Einschränkung der Verarbeitung der Daten zu
                verlangen. Recht auf Datenübertragbarkeit: Sie haben das Recht,
                Sie betreffende Daten, die Sie uns bereitgestellt haben, nach
                Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
                gängigen und maschinenlesbaren Format zu erhalten oder deren
                Übermittlung an einen anderen Verantwortlichen zu fordern.
                Beschwerde bei Aufsichtsbehörde: Sie haben unbeschadet eines
                anderweitigen verwaltungsrechtlichen oder gerichtlichen
                Rechtsbehelfs das Recht auf Beschwerde bei einer
                Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts
                des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
                Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
                die Vorgaben der DSGVO verstößt. Begriffsdefinitionen In diesem
                Abschnitt erhalten Sie eine Übersicht über die in dieser
                Datenschutzerklärung verwendeten Begrifflichkeiten. Viele der
                Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO
                definiert. Die gesetzlichen Definitionen sind verbindlich. Die
                nachfolgenden Erläuterungen sollen dagegen vor allem dem
                Verständnis dienen. Die Begriffe sind alphabetisch sortiert.
              </p>
              <p>
                IP-Masking: Als "IP-Masking” wird eine Methode bezeichnet, bei
                der das letzte Oktett, d.h., die letzten beiden Zahlen einer
                IP-Adresse, gelöscht wird, damit die IP-Adresse nicht mehr der
                eindeutigen Identifizierung einer Person dienen kann. Daher ist
                das IP-Masking ein Mittel zur Pseudonymisierung von
                Verarbeitungsverfahren, insbesondere im Onlinemarketing
                Personenbezogene Daten: "Personenbezogene Daten“ sind alle
                Informationen, die sich auf eine identifizierte oder
                identifizierbare natürliche Person (im Folgenden "betroffene
                Person“) beziehen; als identifizierbar wird eine natürliche
                Person angesehen, die direkt oder indirekt, insbesondere mittels
                Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
                zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu
                einem oder mehreren besonderen Merkmalen identifiziert werden
                kann, die Ausdruck der physischen, physiologischen, genetischen,
                psychischen, wirtschaftlichen, kulturellen oder sozialen
                Identität dieser natürlichen Person sind. Profile mit
                nutzerbezogenen Informationen: Die Verarbeitung von "Profilen
                mit nutzerbezogenen Informationen", bzw. kurz "Profilen" umfasst
                jede Art der automatisierten Verarbeitung personenbezogener
                Daten, die darin besteht, dass diese personenbezogenen Daten
                verwendet werden, um bestimmte persönliche Aspekte, die sich auf
                eine natürliche Person beziehen (je nach Art der Profilbildung
                können dazu unterschiedliche Informationen betreffend die
                Demographie, Verhalten und Interessen, wie z.B. die Interaktion
                mit Webseiten und deren Inhalten, etc.) zu analysieren, zu
                bewerten oder, um sie vorherzusagen (z.B. die Interessen an
                bestimmten Inhalten oder Produkten, das Klickverhalten auf einer
                Webseite oder den Aufenthaltsort). Zu Zwecken des Profilings
                werden häufig Cookies und Web-Beacons eingesetzt.
                Verantwortlicher: Als "Verantwortlicher“ wird die natürliche
                oder juristische Person, Behörde, Einrichtung oder andere
                Stelle, die allein oder gemeinsam mit anderen über die Zwecke
                und Mittel der Verarbeitung von personenbezogenen Daten
                entscheidet, bezeichnet. Verarbeitung: "Verarbeitung" ist jeder
                mit oder ohne Hilfe automatisierter Verfahren ausgeführte
                Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
                personenbezogenen Daten. Der Begriff reicht weit und umfasst
                praktisch jeden Umgang mit Daten, sei es das Erheben, das
                Auswerten, das Speichern, das Übermitteln oder das Löschen.
                Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
                Schwenke
              </p>
            </Typo>
          </AccordionPanel>
        </StyledAccordionItem>
      </ReachAccordion>
    </Section>
  )
}
