import styled, { css } from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const StyledImage = styled(LazyLoadImage)(
  ({ $backgroundImage, $isSliderImage, theme }) => css`
    width: 100%;
    align-self: center;
    /* max-width: 800px; */

    ${$backgroundImage
      ? css`
          position: absolute;
          min-width: 100vw;
          height: 100vh;
          object-fit: cover;
        `
      : css`
          height: auto;
        `}
    ${$isSliderImage &&
    css`
      height: 100%;
      width: auto;
      padding: 0 ${theme.spacing.m};
    `}
  `
)
