import { Section } from '../Section/Section'
import {
  StyledCard,
  CardWrapper,
  StyledCardHeading,
  StyledCardPrice,
  StyledCardList,
  StyledCardListItem,
  StyledCardButtonWrapper,
} from './CardSection.styled'
import { Button } from '../../components/Button/Button'

const cards = [
  {
    title: 'Small',
    price: '280€',
    features: [
      'Dauer ca. 60 Minuten',
      'Auswahl aus 200 Bildmotiven',
      '7 bearbeitete digitale Dateien',
      'Outfit- & Locationwechsel möglich',
    ],
  },

  {
    title: 'Medium',
    price: '340€',
    features: [
      'Dauer ca. 90 Minuten',
      'Auswahl aus 350 Bildmotiven',
      '10 bearbeitete digitale Dateien',
      'Abzüge Deiner Bilder 13x18cm inklusive',
      'Outfit- & Locationwechsel möglich',
    ],
  },
  {
    title: 'Large',
    price: '410€',
    features: [
      'Dauer ca. 120 Minuten',
      'Auswahl aus 500 Bildmotiven',
      '15 bearbeitete digitale Dateien',
      'Abzüge Deiner Bilder 13x18cm inklusive',
      'Outfit- & Locationwechsel möglich',
    ],
  },

  // {
  //   title: 'Gruppenpaket',
  //   price: '20%',
  //   features: ['feature 1', 'feature 2', 'feature 3', 'feature 4', 'feature 5'],
  // },
  // {
  //   title: 'Turnierfotografie',
  //   price: '69€',
  //   features: ['feature 1', 'feature 2', 'feature 3', 'feature 4', 'feature 5'],
  // },
]


export const CardSection = ({ open, setData }) => {
  return (
    <Section>
      <CardWrapper>
        {cards.map((card, index) => {
          const { features, title, price } = card
          return (
            <StyledCard key={index}>
              <StyledCardHeading>{title}</StyledCardHeading>
              <StyledCardPrice>{price}</StyledCardPrice>
              <StyledCardList>
                {features.map((feature, index) => (
                  <StyledCardListItem key={index}>{feature}</StyledCardListItem>
                ))}
              </StyledCardList>
              <StyledCardButtonWrapper>
                <Button
                  actionType="primary"
                  inverted
                  onClick={() => setData(title, 'Shooting')}>
                  Jetzt buchen
                </Button>
              </StyledCardButtonWrapper>
            </StyledCard>
          )
        })}
      </CardWrapper>
    </Section>
  )
}
