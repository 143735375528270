import {
  StyledHorizontalScroll,
  SrcollIndicator,
  StyledMobileScroll,
} from './Carousel.styled'
import { Image } from '../Image/Image'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

export const Carousel = ({ images, offset }) => {
  const { width } = useWindowDimensions()

  return (
    <>
      {Boolean(width >= 768) ? (
        <>
          <StyledHorizontalScroll
            reverseScroll
            offset={offset}
            animValues={100}>
            {images.map((image, index) => {
              return <Image key={index} src={image} isSliderImage />
            })}
          </StyledHorizontalScroll>
          <SrcollIndicator>Scroll down &darr;</SrcollIndicator>
        </>
      ) : (
        <>
          <StyledMobileScroll>
            {images.map((image, index) => {
              return <Image key={index} src={image} isSliderImage />
            })}
          </StyledMobileScroll>
          <SrcollIndicator>Scroll right &rarr;</SrcollIndicator>
        </>
      )}
    </>
  )
}
