const breakpoints = {
  xs: '576px',
  s: '768px',
  m: '992px',
  l: '1200px',
}

const base = 8

export const theme = {
  font: {
    headline: 'Quickpen, Zapfino, decorative',
    text: 'Nobel, Open Sans, Helvetica, sans-serif',
  },
  fontSize: {
    xs: '1rem', // 16px
    s: '1.25rem', // 20px
    m: '1.75rem', // 24px
    l: '2.25rem', // 32px
    xl: '2.75rem', // 44px
    xxl: '3.25rem', // 52px
    superxl: '5rem', // 80px
  },
  color: {
    primary: '#9A5660',
    secondary: '#E6D2C6',
    tertiary: '#FDF6F2',
    white: '#FFFFFF',
    black: '#000000',
    light: '#F2F2F2',
    dark: '#404040',
  },
  breakpoint: {
    s: `@media (min-width: ${breakpoints.xs})`,
    m: `@media (min-width: ${breakpoints.s})`,
    l: `@media (min-width: ${breakpoints.m})`,
    xl: `@media (min-width: ${breakpoints.l})`,
    maxS: `@media (max-width: ${breakpoints.xs})`,
    maxM: `@media (max-width: ${breakpoints.s})`,
    maxL: `@media (max-width: ${breakpoints.m})`,
    maxXl: `@media (max-width: ${breakpoints.l})`,
  },
  spacing: {
    xs: `${base / 2}px `, // 4px
    s: `${base}px`, // 8px
    m: `${base * 1.5}px`, // 12px
    l: `${base * 2}px`, // 16px
    xl: `${base * 3}px`, // 24px
    xxl: `${base * 4}px`, // 32px
    superxl: `${base * 5}px`, // 40px
  },
}
