import styled, { css } from 'styled-components'
import { ReactComponent as Logo } from '../../media/images/logo.svg'

export const StyledNavBlocker = styled.div(
  ({ onlyMobile, theme }) => css`
    height: 92px;
    width: 100%;
    display: block;

    ${theme.breakpoint.l} {
      height: ${onlyMobile ? '0' : '140px'};
    }
  `
)

export const StyledCircle = styled.div(
  ({ theme }) => css`
    position: absolute;
    content: '';
    height: 46px;
    width: 46px;
    right: 22px;
    top: 22px;
    border-radius: 22px;
    background-color: ${theme.color.primary};
    opacity: 0.7;

    ${theme.breakpoint.l} {
      display: none;
    }
  `
)

export const StyledNavigation = styled.nav(
  ({ $isScrolled, theme, inverted }) => {
    return css`
      position: fixed;
      top: 0;
      width: 100%;
      transition: background 0.5s ease-out;
      z-index: 4;

      #menuToggle {
        display: block;
        position: relative;
        z-index: 5;
        -webkit-user-select: none;
        user-select: none;
      }

      #menuToggle input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: ${theme.spacing.l};
        right: ${theme.spacing.xl};
        cursor: pointer;
        opacity: 0;
        z-index: 6;
        -webkit-touch-callout: none;

        &:checked ~ ${StyledCircle} {
          display: none;
        }

        &:checked ~ span {
          width: 33px;
          opacity: 1;
          transform: rotate(45deg) translate(-4px, -1px);
          background: ${theme.color.dark};
        }

        &:checked ~ ul {
          opacity: 1;
          right: 0;
          transition: right 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

          ${StyledListItem} {
            ${theme.breakpoint.laxM} {
              a,
              button {
                color: ${theme.color.dark};

                > span {
                  color: ${theme.color.dark};
                }
              }
            }
          }
        }

        &:checked ~ span:nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        &:checked ~ span:nth-last-child(2) {
          transform: rotate(-45deg) translate(-1px, -1px);
        }

        &:checked ~ ul {
          transform: none;
        }
      }

      #menuToggle > span {
        display: block;
        width: 30px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;
        left: calc(100% - 60px);
        top: 36px;
        background: ${theme.color.light};
        /* box-shadow: 0px 0px 10px 1px ${theme.color.dark}; */
        border-radius: 3px;
        z-index: 5;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

        &:first-of-type {
        }

        &:nth-last-child(2) {
          transform-origin: 0% 100%;
        }
      }

      ${theme.breakpoint.l} {
        background: ${$isScrolled && theme.color.white};
        box-shadow: ${$isScrolled && `0px 0px 9px -5px #000`};

        #menuToggle input,
        #menuToggle > span {
          display: none;
        }
      }
    `
  }
)

export const StyledList = styled.ul(({ theme, $isScrolled }) => {
  return css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 75px ${theme.spacing.superxl};
    background: ${theme.color.light};
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100vh;
    width: 50%;
    min-width: 300px;
    opacity: 0;
    right: -200%;
    transition: right 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

    ${theme.breakpoint.l} {
      background: transparent;
      position: relative;
      right: auto;
      height: 140px;
      width: 100%;
      max-width: 1200px;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      flex-direction: row;
      transform: none;
      opacity: 1;
    }
  `
})

export const StyledListItem = styled.li(
  ({ theme, inverted, $isScrolled, withSubNav }) => {
    return css`
      list-style: none;
      height: auto;
      padding-bottom: 50px;
      position: relative;
      z-index: 10;

      ${theme.breakpoint.l} {
        padding: 0 ${theme.spacing.l} 0;
      }

      a,
      button {
        background: transparent;
        text-decoration: none;
        border: none;
        font-size: ${theme.fontSize.m};
        color: ${$isScrolled
          ? theme.color.black
          : inverted
          ? theme.color.white
          : theme.color.black};
        position: relative;
        text-decoration: none;
        transition: color 0.3s ease;

        ::before {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          height: 2px;
          bottom: -2px;
          left: 1px;
          background-color: ${theme.color.black};
          visibility: hidden;
          transition: all 0.3s ease;

          ${theme.breakpoint.l} {
            background-color: ${$isScrolled
              ? theme.color.black
              : inverted
              ? theme.color.white
              : theme.color.black};
          }
        }

        button {
          z-index: 9;
        }

        > span {
          margin-left: ${theme.spacing.m};
          position: relative;
          bottom: 2px;
          font-size: ${theme.fontSize.xs};
          display: inline-block;
          transition: transform 0.3s ease-in-out;
          color: ${$isScrolled
            ? theme.color.black
            : inverted
            ? theme.color.white
            : theme.color.black};
        }
      }

      a:hover::before {
        visibility: visible;
        width: 70%;
      }

      &:hover {
        ${theme.breakpoint.l} {
          button span {
            transform: rotate(180deg);
          }

          ${StyledSubNav} {
            visibility: visible;
            height: auto;
            padding: ${theme.spacing.xl} 0 ${theme.spacing.l};
          }
        }
      }

      &.active {
        ${theme.breakpoint.maxM} {
          ${withSubNav &&
          css`
            padding-bottom: 30px;
          `}

          button span {
            transform: rotate(180deg);
          }

          ${StyledSubNav} {
            visibility: visible;
            height: auto;
            padding: ${theme.spacing.l} 0 0;
          }
        }
      }
    `
  }
)

export const StyledLogo = styled(Logo)(
  ({ theme, inverted, $isScrolled, hasMobileLogo }) => css`
    width: 200px;
    height: auto;
    ${hasMobileLogo &&
    `position: absolute;
    top: 10px;`}

    > * {
      fill: ${theme.color.black}!important;

      ${theme.breakpoint.l} {
        fill: ${$isScrolled
          ? theme.color.black
          : inverted
          ? theme.color.white
          : theme.color.black}!important;
      }
    }
  `
)

export const StyledSubNav = styled.ul(
  ({ theme, inverted, $isScrolled }) => css`
    visibility: hidden;
    width: max-content;
    height: 0;

    ${StyledListItem} {
      padding-bottom: 0;
      margin-bottom: ${theme.spacing.xl};

      &:last-of-type {
        margin-bottom: 0;
      }

      a {
        font-size: ${theme.fontSize.s};
      }
    }

    ${theme.breakpoint.l} {
      background-color: ${!inverted
        ? theme.color.white
        : $isScrolled
        ? theme.color.white
        : 'transparent'};
      position: absolute;
      box-shadow: ${$isScrolled && `0px 7px 9px -12px #000`};
      left: 0;
      padding: ${theme.spacing.l} 0;

      ${StyledListItem} {
        margin-bottom: ${theme.spacing.l};
      }
    }
  `
)
