import styled, { css } from 'styled-components'

export const CardWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    grid-gap: 50px ${theme.spacing.superxl};
    margin: ${theme.spacing.superxl};

    ${theme.breakpoint.m} {
      flex-direction: row;
    }
  `
)

export const StyledCard = styled.article(
  ({ theme }) => css`
    padding: ${theme.spacing.xl};
    width: 100%;
    background-color: ${theme.color.tertiary};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;

    ${theme.breakpoint.m} {
      width: calc(50% - ${theme.spacing.superxl});
    }

    ${theme.breakpoint.l} {
      width: calc(33.33% - ${theme.spacing.superxl});
    }
  `
)

export const StyledCardHeading = styled.h3(
  ({ theme }) => css`
    text-align: center;
    font-size: ${theme.fontSize.m};
    text-transform: uppercase;
    font-weight: bold;
  `
)

export const StyledCardPrice = styled.p(
  ({ theme }) => css`
    text-align: center;
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: bold;
    margin: ${theme.spacing.m} 0 ${theme.spacing.l};
  `
)

export const StyledCardList = styled.ul(
  ({ theme }) => css`
    border-top: 2px solid ${theme.color.primary};
    padding: ${theme.spacing.l};
  `
)

export const StyledCardListItem = styled.li(
  ({ theme }) => css`
    margin: 0 0 ${theme.spacing.l} 17px;
    font-size: ${theme.fontSize.xs};
  `
)

export const StyledCardButtonWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: -40px;
  `
)
