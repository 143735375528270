import { StyledHeader, StyledHeaderContent } from './Header.styled'

export const Header = ({ src, isCentered, children }) => {
  return (
    <StyledHeader isCentered={isCentered} src={src}>
      <StyledHeaderContent isCentered={isCentered}>
        {children}
      </StyledHeaderContent>
    </StyledHeader>
  )
}
