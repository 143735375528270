import { ImageText } from '../../components/ImageText/ImageText'
import { Navigation } from '../../components/Navigation/Navigation'
import { Typo } from '../../components/Typo/Typo/Typo'
import { Heading } from '../../components/Typo/Heading/Heading'
import feature01 from '../../media/images/feature_home_showcase.png'
import fotografin from '../../media/images/feature_home_fotografin.png'
import shooting from '../../media/images/feature_home_shooting.png'
import instagram from '../../media/images/feature_home_instagram.png'
import { Section } from '../../components/Section/Section'
import { Header } from '../../components/Header/Header'
import headerImage from '../../media/images/header_home.jpg'
import { Button } from '../../components/Button/Button'
import { Parallax } from '../../components/Parallax/Parallax'
import parallax from '../../media/images/feature_home_gutschein.jpg'
import { GalleryNavigation } from '../../components/GalleryNavigation/GalleryNavigation'
import { QuoteSlider } from '../../components/QuoteSlider/QuoteSlider'
import { Footer } from '../../components/Footer/Footer'
// import Fade from 'react-reveal/Fade'
import { Icon } from '../../components/Icon/Icon'
import { ReactComponent as Logo } from '../../media/images/logo.svg'
import { Helmet } from 'react-helmet'
import { Modal } from '../../components/Modal/Modal'
import { useState } from 'react'
import { Formular } from '../../components/Form/Form'
import { TextWrapper } from '../../components/Typo/TextWrapper'

const Head = () => (
  <Helmet>
    <title>Lea Ernst Photography</title>
    <meta name="description" content="" />
    <meta name="keywords" content="" />
  </Helmet>
)

export const Home = () => {
  const [showDialog, setShowDialog] = useState(false)
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  return (
    <>
      <Head />
      <Navigation hasLogo inverted />
      <Header src={headerImage} isCentered>
        <Logo style={{ width: '45vw', minWidth: '350px' }} />
      </Header>
      <Modal
        open={open}
        close={close}
        showDialog={showDialog}
        title="Kontaktformular">
        <Formular />
      </Modal>
      {/* <Accordion /> */}
      {/* <CardSection /> */}
      <main>
        <ImageText src={feature01} alt="">
          <Heading primary typo="h2" fontSize="xl">
            … every picture <br />
            tells a story!
          </Heading>
          <Typo>
            Oft sind es die kleinen Momente – meist wenige Sekunden, die reichen
            um ein einfaches Bild zu Deinem Lieblingsbild zu machen. <br /> Eine
            Erinnerung für die Ewigkeit ♡
          </Typo>
        </ImageText>

        <Section textAlign="center">
          <TextWrapper>
            <Heading primary typo="h2" fontSize="xl">
              Die Fotogalerie
            </Heading>
            <Heading typo="h3">Erinnerungen & Momente für die Ewigkeit</Heading>
            <Typo>
              Lass uns gemeinsam die schönsten Momente festhalten. Denn es sind
              die kleinen Dinge im Leben die großen Wert haben. Ich möchte Dich
              einladen Dir einen kleinen Einblick in meine Fotografie zu
              gewähren und Dir meine Art zu arbeiten näher zu bringen. Mein
              exklusives Portfolio enthält einen Überblick über meine auf Reisen
              entstandenen Fotogeschichten und Erlebnisse als Fotografin. Nimm
              Dir Zeit in Ruhe zu stöbern, vielleicht findest du Inspiration für
              dein persönliches Fotoshooting mit mir?
            </Typo>
          </TextWrapper>
        </Section>

        <GalleryNavigation />

        <ImageText
          src={fotografin}
          alt="Lea Ernst"
          backgroundColor="#E6D2C6"
          reverse>
          <Heading primary typo="h2" fontSize="xl">
            Deine Fotografin
          </Heading>
          <Heading typo="h3">Wer steht hinter der Kamera?</Heading>
          <Typo>
            Hallo mein Name ist Lea Ernst, ich bin Fotografin aus purer
            Leidenschaft und im schönen Bayern zuhause. Ich habe mich auf die
            Tierfotografie und im besonderen auf die Pferdefotografie
            spezialisiert. Allerdings fotografiere ich gerne auf Hochzeiten,
            Paarshootings, Portraits und vieles mehr … Ich habe eine Ausbildung
            zur Mediengestalterin im Bereich Digital und Print abgeschlossen und
            arbeite derzeit als Grafikerin.
          </Typo>

          <Button isRouter actionType="primary" to="/aboutme">
            About me
          </Button>
        </ImageText>

        <ImageText src={shooting} alt="">
          <Heading primary typo="h2" fontSize="xl">
            Dein Fotoshooting
          </Heading>
          <Heading typo="h3">Dein persönliches Fotoshooting bei Mir</Heading>
          <Typo>
            Jeder Moment ist etwas besonderes und verdient es für immer auf
            einem Foto festgehalten zu werden. Ein Shooting bei mir kannst Du
            ganz individuell nach deinen Wünschen und Vorstellungen gestalten.
            Dabei kannst Du aus verschiedenen Shootingpaketen wählen. Natürliche
            berate ich Dich jederzeit gerne, gehe auch auf besondere Wünsche ein
            und versuche diese gemeinsam mit Dir umzusetzen.
          </Typo>

          <Button actionType="primary" onClick={open} isButton>
            Jetzt buchen
          </Button>
        </ImageText>

        <Parallax src={parallax} alt="" textAlign="center">
          <Heading primary typo="h2" inverted fontSize="xl">
            Onlinegalerie
          </Heading>
          <Typo>
            Deine schönsten Bilder findest Du hier in meiner Onlinegalerie, zum
            downloaden und bestellen. <br />
            Ich wünsche Dir viel Freude beim aussuchen.
          </Typo>
          <Button href="https://www.pictrs.com/leaernst-photography?l=de">
            Zur Onlinegalerie
          </Button>
        </Parallax>

        <ImageText src={instagram} alt="" reverse>
          <Heading primary typo="h2" fontSize="xl">
            Instagram & Facebook
          </Heading>
          <Heading typo="h3">Du findest mich auch auf Socialmedia</Heading>
          <Typo>
            Schaue doch gerne mal auf meinen Socialmedia-Kanälen vorbei. Dort
            findest du weitere tolle Impressionen von mir und meiner Arbeit.
            Zudem gibt es dort auch tolle Verlosungen und Spannende Tipps rund
            um das Thema Fotografie.
            <br /> <br />
            Ich freue mich über Deinen Besuch! <br />
            Deine Lea
          </Typo>
          <Icon
            aria="Facebook"
            type="facebook"
            href="https://www.facebook.com/leaernstphotography/"
          />
          <Icon
            aria="Instagram"
            type="instagram"
            href="https://www.instagram.com/lea.ernst.photography"
          />
        </ImageText>

        <QuoteSlider />
      </main>
      <Footer hasSocials />
    </>
  )
}
