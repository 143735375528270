import { Section } from '../Section/Section'
import { Image } from '../Image/Image'
import { StyledImageText, StyledContent } from './ImageText.styled'

export const ImageText = ({ src, alt, children, backgroundColor, reverse }) => {
  return (
    <Section backgroundColor={backgroundColor}>
      <StyledImageText reverse={reverse}>
        <StyledContent>{children}</StyledContent>
        <Image src={src} alt={alt} />
      </StyledImageText>
    </Section>
  )
}
