import { StyledFieldSet, StyledArrow } from './Form.styled'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { Button } from '../../components/Button/Button'
import { send } from 'emailjs-com'
import * as Yup from 'yup'

const initialData = {
  type: '',
  packet: '',
}

export const Formular = ({ data = initialData }) => {
  const issueType = ['Shooting', 'Allgemein']
  const { type, packet } = data
  const validationSchema = Yup.object({
    firstName: Yup.string().required('Bitte geben Sie Ihren Vornamen ein.'),
    lastName: Yup.string().required('Bitte geben Sie Ihren Nachnamen ein.'),
    email: Yup.string()
      .email()
      .required('Bitte geben Sie eine gültige Email an.'),
    issue: Yup.string()
      .required('Bitte wählen Sie ein Anliegen aus.')
      .oneOf(issueType),
    message: Yup.string(),
  })

  const message = packet ? `☑ Shootingpaket: ${packet}` : ''
  const issue = type ? type : ''

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    issue,
    message,
  }
  const onSubmit = (values) => {
    send(
      'service_b8hlgkb',
      'template_56wj33w',
      values,
      'user_csWkRt36iTx7ZFkKToHI9'
    )
      .then((response) => {
        alert('Email wurde erfolgreich versendet.')
      })
      .catch((err) => {
        alert('Etwas hat nicht funktioniert, bitte versuchen Sie es erneut.')
      })
  }

  const renderError = (message) => <p className="help is-danger">{message}</p>
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values)
        resetForm()
      }}>
      <Form>
        <div>
          <StyledFieldSet>
            <label htmlFor="firstName">Vorname</label>
            <Field name="firstName" type="text" placeholder="Vorname" />
            <ErrorMessage name="firstName" render={renderError} />
          </StyledFieldSet>

          <StyledFieldSet>
            <label htmlFor="lastName">Nachname</label>
            <Field
              name="lastName"
              type="text"
              className="input"
              placeholder="Nachname"
            />
            <ErrorMessage name="lastName" render={renderError} />
          </StyledFieldSet>

          <StyledFieldSet>
            <label htmlFor="email">Email address</label>
            <Field name="email" type="text" placeholder="Email address" />
            <ErrorMessage name="email" render={renderError} />
          </StyledFieldSet>

          <StyledFieldSet>
            <label htmlFor="issue">Anliegen</label>
            <StyledArrow />
            <Field name="issue" as="select">
              <option value={issue} disabled hidden>
                {issue === 'Shooting'
                  ? 'Anfrage Fotoshooting'
                  : 'Wählen Sie ein Anliegen'}
              </option>
              <option value="Shooting" selected>
                Anfrage Fotoshooting
              </option>
              <option value="Allgemein">Allgemeine Anfrage</option>
            </Field>
            <ErrorMessage name="issue" render={renderError} />
          </StyledFieldSet>

          <StyledFieldSet>
            <label htmlFor="message">Ihre Nachricht</label>
            <Field name="message" as="textarea" placeholder="Ihre Nachricht" />
            <ErrorMessage name="message" render={renderError} />
          </StyledFieldSet>

          <Button isButton type="submit" actionType="primary">
            Absenden
          </Button>
        </div>
      </Form>
    </Formik>
  )
}
