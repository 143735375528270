import { Icon } from '../Icon/Icon'
import {
  StyledFooter,
  StyledFooterLink,
  StyledTypo,
  StyledLogo,
  FooterBar,
} from './Footer.styled'
import { Typo } from '../Typo/Typo/Typo'
import { HashLink } from 'react-router-hash-link'

export const Footer = ({ hasSocials }) => {
  return (
    <StyledFooter hasSocials={hasSocials}>
      {hasSocials && (
        <>
          <article>
            <StyledTypo fontSize="s">Follow me</StyledTypo>
            <Icon
              aria="Facebook"
              type="facebook"
              href="https://www.facebook.com/leaernstphotography/"
            />
            <Icon
              aria="Instagram"
              type="instagram"
              href="https://www.instagram.com/lea.ernst.photography"
            />
          </article>
          <article>
            <StyledLogo />
          </article>
          <article>
            {/* <StyledFooterLink to="/construction">About Me</StyledFooterLink> */}
            <StyledFooterLink to="/pferde-fotografie">Portfolio</StyledFooterLink>
            <StyledFooterLink to="/preise-leistungen">Shooting</StyledFooterLink>
            <StyledFooterLink to="/kontakt">Kontakt</StyledFooterLink>
          </article>
        </>
      )}
      <FooterBar>
        <Typo fontSize="xs">
          © 2021 LEA ERNST PHOTOGRAPHY - ALL RIGHTS RESERVED &nbsp;|&nbsp;
          <HashLink to="/kontakt#agb"> AGB </HashLink> &nbsp;|&nbsp;
          <HashLink to="/kontakt#impressum"> IMPRESSUM </HashLink> &nbsp;|&nbsp;
          <HashLink to="/kontakt#datenschutz"> DATENSCHUTZ </HashLink>
        </Typo>
      </FooterBar>
    </StyledFooter>
  )
}
