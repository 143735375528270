import { StyledIcon } from './Icon.styled'
import { ReactComponent as Instagram } from '../../media/images/instagram.svg'
import { ReactComponent as Facebook } from '../../media/images/facebook.svg'

export const Icon = ({ type, children, color, href, aria }) => {
  const getIconContent = () => {
    if (children) {
      return children
    }
    switch (type) {
      case 'instagram':
        return <Instagram />
      case 'facebook':
        return <Facebook />
      default:
        return null
    }
  }
  return (
    <StyledIcon color={color} href={href} aria-label={aria}>
      {getIconContent()}
    </StyledIcon>
  )
}
