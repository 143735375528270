import { Navigation } from '../../components/Navigation/Navigation'
import { Typo } from '../../components/Typo/Typo/Typo'
import { Heading } from '../../components/Typo/Heading/Heading'
import { Section } from '../../components/Section/Section'
import { Footer } from '../../components/Footer/Footer'
import { Helmet } from 'react-helmet'
import errorImage from '../../media/images/404_page.png'
import { Image } from '../../components/Image/Image'
import { Button } from '../../components/Button/Button'
import { TextWrapper } from '../../components/Typo/TextWrapper'

const Head = () => (
  <Helmet>
    <title>Lea Ernst Photography</title>
    <meta name="description" content="" />
    <meta name="keywords" content="" />
  </Helmet>
)

export const Four0Four = () => {
  return (
    <>
      <Head />
      <Navigation hasLogo />

      <main>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Section textAlign="center">
          <TextWrapper>
            <script async src="//www.instagram.com/embed.js"></script>
            <Image src={errorImage} />
            <Heading tag="h3">Diese Seite existiert leider nicht.</Heading>
            <Typo>Hier gelangst du direkt wieder zur Startseite:</Typo>
            <Button isRouter actionType="primary" to="/">
              Zur Startseite
            </Button>
          </TextWrapper>
        </Section>
      </main>
      <Footer hasSocials />
    </>
  )
}
