import { StyledSection } from './Section.styled'

export const Section = ({
  backgroundColor,
  textColor,
  children,
  textAlign,
  fullwidth = true,
}) => (
  <StyledSection
    fullwidth={fullwidth}
    backgroundColor={backgroundColor}
    textColor={textColor}
    textAlign={textAlign}>
    <div>{children}</div>
  </StyledSection>
)
