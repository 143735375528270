import styled, { css } from 'styled-components'

export const StyledContentWrapper = styled.div(({ inverted, theme }) => {
  return css`
    margin: 140px 0;
    position: relative;
    z-index: 1;

    &::before {
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      /* pointer-events: none; */
      z-index: -1;
      top: 0;
      left: 0;
      background: #282729;
      opacity: 0.6;
    }

    section {
      max-width: 1200px !important;
      padding: ${theme.spacing.m};

      h3 {
        ${theme.breakpoint.m} {
          font-size: ${theme.fontSize.superxl};
        }
      }
    }
  `
})
