import { Navigation } from '../../components/Navigation/Navigation'
import { Heading } from '../../components/Typo/Heading/Heading'
import { Typo } from '../../components/Typo/Typo/Typo'
import { Accordion } from '../../components/Accordion/Accordion'
import { Footer } from '../../components/Footer/Footer'
import { StyledFormSection, StyledContent } from './Contact.styled'
import { Helmet } from 'react-helmet'
import { Formular } from '../../components/Form/Form'
import { StyledNavBlocker } from '../../components/Navigation/Navigation.styled'

const Head = () => (
  <Helmet>
    <title>Lea Ernst Photography</title>
    <meta name="description" content="" />
    <meta name="keywords" content="" />
  </Helmet>
)

export const Contact = () => {
  return (
    <>
      <Head />
      <Navigation hasLogo hasMobileLogo />
      <main>
        <StyledNavBlocker onlyMobile />
        <StyledFormSection>
          <StyledContent>
            <div>
              <Heading primary typo="h1" color="primary" fontSize="xl">
                Kontakt
              </Heading>
              <Heading typo="h3">Lea Ernst Photography</Heading>
              <Typo>
                Rolf-Pinegger-Straße 28 <br />
                80689 München <br />
                www.leaernstphotography.de <br />
                <a href="mailto:info@leaernstphotography.de">
                  info@leaernstphotography.de
                </a>
                <br />
                +49 174 931 80 82
              </Typo>
            </div>
            <div>
              <Heading typo="h3">Kontaktiere mich gerne!</Heading>
              <Formular />
            </div>
          </StyledContent>
        </StyledFormSection>
        <Accordion />
      </main>
      <Footer hasSocials />
    </>
  )
}
