import { AccordionButton, AccordionItem } from '@reach/accordion'
import styled, { css } from 'styled-components'

export const StyledAccordionButton = styled(AccordionButton)(
  ({ theme }) => css`
    border: none;
    background: ${theme.color.tertiary};
    width: 100%;
    text-align: left;
    padding: ${theme.spacing.xl};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.l};
    cursor: pointer;

    &[aria-expanded='false'] {
      .close {
        display: none;
      }
    }

    &[aria-expanded='true'] {
      .close {
        display: block;
      }

      .open {
        display: none;
      }
    }

    span {
      font-size: ${theme.fontSize.m};
    }
  `
)

export const StyledAccordionItem = styled(AccordionItem)(
  ({ theme }) => css`
    border: none;
    background: transparent;
    div {
      padding: ${theme.spacing.xl};
      article {
        ul {
          margin-bottom: ${theme.spacing.l};
          li {
            list-style: inside;
          }
        }
        p {
          margin-bottom: ${theme.spacing.l};
        }
      }
    }
  `
)
